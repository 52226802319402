<div class="row row-cols-1 row-cols-sm-2 row-cols-xxl-3 g-4">
    <div class="col" *ngFor="let el of questionBlock.SummaryItemList; let i=index">
        <div class="card h-100">
            <!-- <img src="..." class="card-img-top" alt="..."> -->
            <div class="card-body">
                <div class="mb-4" style="display: inline-flex;  align-items: center;">
                    <ng-container [ngSwitch]="true">
                        <svg class="va-icon va-icon-directions_car me-2"
                            *ngSwitchCase="getIconType(questionBlock.QuestionBlockTypeCode, el) === iconEnum.Vehicle">
                            <use xlink:href="#va-icon-directions_car"></use>
                        </svg>
                        <img class="va-icon" src="assets/font-icons/human-female.png"
                            *ngSwitchCase="getIconType(questionBlock.QuestionBlockTypeCode, el) === iconEnum.GenderFemale">

                        <img class="va-icon" src="assets/font-icons/human-male.png"
                            *ngSwitchCase="getIconType(questionBlock.QuestionBlockTypeCode, el) === iconEnum.GenderMale">


                    </ng-container>

                    <h2 class="card-title" >
                        <!-- <small>{{this.questionBlock.SummaryItemHeaderDict[1].LabelText}}</small> -->
                        <strong class="word-wrapping" *ngIf="questionBlock.QuestionBlockTypeCode == 'GreetingWebIntroVehicleSummary'">{{cardTitle[i]}}</strong>
                        <strong class="word-wrapping" *ngIf="questionBlock.QuestionBlockTypeCode != 'GreetingWebIntroVehicleSummary'">{{el.Column1}}</strong>
                    </h2>
                </div>
                <div *ngIf="questionBlock.QuestionBlockTypeCode == 'GreetingWebIntroVehicleSummary'" class="d-flex justify-content-between">
                    <strong  class="pe-4"><u>Vehicle Details</u></strong>
                </div>
                <div *ngIf="questionBlock.SummaryItemHeaderDict[2]" class="d-flex justify-content-between">
                    <small class="pe-4">{{questionBlock.SummaryItemHeaderDict[2]?.LabelText}}:</small>
                    <strong  class="word-wrapping" [innerHTML]="el.Column2"></strong>
                </div>
                <div *ngIf="questionBlock.SummaryItemHeaderDict[3]" class="d-flex justify-content-between">
                    <small  class="pe-4">{{questionBlock.SummaryItemHeaderDict[3]?.LabelText}}:</small>
                    <strong  class="word-wrapping" [innerHTML]="el.Column3"></strong>
                </div>
                <div *ngIf="questionBlock.SummaryItemHeaderDict[1] && questionBlock.QuestionBlockTypeCode == 'GreetingWebIntroVehicleSummary'" class="d-flex justify-content-between">
                    <small class="pe-4">{{questionBlock.SummaryItemHeaderDict[1]?.LabelText}}:</small>
                    <strong  class="word-wrapping" [innerHTML]="el.Column1"></strong>
                </div>
                <div *ngIf="questionBlock.SummaryItemHeaderDict[4]" class="d-flex justify-content-between">
                    <small  class="pe-4">{{questionBlock.SummaryItemHeaderDict[4]?.LabelText}}:</small>
                    <strong  class="word-wrapping" [innerHTML]="el.Column4"></strong>
                </div>
                <div *ngIf="questionBlock.SummaryItemHeaderDict[5]" class="d-flex justify-content-between">
                    <small  class="pe-4">{{questionBlock.SummaryItemHeaderDict[5]?.LabelText}}:</small>
                    <strong  class="word-wrapping" [innerHTML]="el.Column5"></strong>
                </div>
                <div *ngIf="questionBlock.SummaryItemHeaderDict[6]" class="d-flex justify-content-between">
                    <small  class="pe-4">{{questionBlock.SummaryItemHeaderDict[6]?.LabelText}}:</small>
                    <strong  class="word-wrapping" [innerHTML]="el.Column6"></strong>
                </div>
                <div class="mt-4">
                    <ng-container *ngFor="let collector of sort(el.CollectorModelList)" class="form-row">
                        <app-question-form-collector [collector]="collector" [form]="form.get('SummaryItemList')"
                            class="mb-1" [class]="{newline: collector.FormInputTypeCode !== 'ActionButton'}">
                        </app-question-form-collector>
                    </ng-container>
                </div>
                <div class="d-block gap-2">
                </div>

            </div>
            <div class="card-footer">
                <ng-container *ngFor="let action of sort(el.SummaryItemActionList)" class="form-row">
                    <button class="btn btn-primary btn-sm rounded-2 mx-1 mb-1" type="submit"
                        (click)="onSubmit($event, action, el)" [name]="action.SectionTreeTypeCd"
                        [attr.aria-label]="'Button ' + action.LabelText + ' for ' + el.Column1"
                        [id]="action.SectionTreeTypeCd+'_'+i" [value]="action.SectionTreeTypeCd">{{action.LabelText}}
                        <!-- <i class="bi bi-check-circle-fill"></i> -->
                        <svg *ngIf="action.IsCompleted" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                            fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                            <path
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                        </svg>
                    </button>
                </ng-container>
            </div>
        </div>
    </div>
</div>
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges,ViewChild,QueryList,ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PageNodeModel } from '@shared/models/page-node-model';
import { LayoutService } from './layout.service'
import { AuthService } from '@shared/security/auth.service';
import { StorageKey, StorageService } from '@shared/services/storage.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { formatDate } from '@angular/common';


declare var $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @Input() page: PageNodeModel;
  @Output() formEvent = new EventEmitter<any>();
  carrierId: number;
  displayDivClass: string = 'd-block';
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  showAdditionalHelp:boolean = true;
  policyText: string = "Policy"
  private _loginResponse: any;
  whiteLogo: boolean = false;
  constructor(private layoutService: LayoutService, public auth: AuthService, public storage: StorageService, public router: Router, private toastr: ToastrService) { }
  isCollapsed = true;
  logoutBody: string;
  ngOnInit(): void {
    //TODO: factor later
    $(document).ready(function () {
      $("#feedbackDrop").on("hidden.bs.modal", function () {        
        //clear feedback form input controls
        $("#inputEmail").val("");
        $("#message").val("");
        $("#errorMessage").text("");
        $("#feedback")[0].reset();

      });

    });

    const imgFile = this.getCarrierImage();
    if (imgFile) this.favIcon.href = 'assets/logo/' + imgFile;
    this.carrierId = Number( this.storage.getItem(StorageKey.carrierId));

    if(this.carrierId == 104 || this.carrierId == 105 || this.router?.url?.indexOf('myusaa') > 0)
    {
    this.showAdditionalHelp = false;
    this.policyText = "USAA Number";
    this.whiteLogo = true;
    }

    if(this.carrierId == 106)
    {
      this.showAdditionalHelp = false;
    }
    
  }

  ngOnChanges(changes: SimpleChanges): void {
  }


  toggleSidebarPin() {
    this.layoutService.toggleSidebarPin();
  }
  toggleSidebar() {
    this.layoutService.toggleSidebar();
  }


  //question modules 
  getQuestionMenuClasses(menuItem) {
    const classes = {
      'completed': menuItem.isCompleted,
      'current': menuItem.isCurrentModule,
      'disabled': !menuItem.isCompleted
    }
    return classes;
  }
  sortMenuModule(array): any {
    return array.sort((a, b) => a.ModuleOrder - b.ModuleOrder);
  }

  onQuestionMenuClick($event, menu) {
    this.formEvent.emit(menu);
  }

  getCarrierImage() {
    var imgFile = this.storage.getItem(StorageKey.logoFile);
    return imgFile;
  }

  getCarrierName()
  {
    var message = this.storage.getItem(StorageKey.carrierSpokenName) + " Logo. " ;
    if(this.page!=null)
    message = message + "and you are at " + this.page.Title + " page." ;
    else 
    message = message + "and you are at login page." ;

    return message;
  }
  getCarrierNameLogo()
  {
    var message = this.storage.getItem(StorageKey.carrierSpokenName) + " Logo" ;
    return message;

  }
  get loginResponse() {
    if (!this._loginResponse) this._loginResponse = JSON.parse(this.storage.getItem(StorageKey.loginResponse));
    if (this._loginResponse) {
      if(this._loginResponse.IsValidUser)
        this.logoutBody = this._loginResponse?.Policy?.IsCCDone ? `Policy# ${this._loginResponse?.Policy?.PolicyNumber} completes` : `All information must be updated by ${formatDate(this._loginResponse?.Policy?.CallByDate, 'MM/dd/yyyy', 'en')}`;
    }

    return this._loginResponse;
  }

  logout() {
    this.auth.logout();
    this.router.navigate(['login']);
  }

  showSuccess(message, title) {
    this.toastr.success(message, title, { enableHtml: true, disableTimeOut: true, /*extendedTimeOut: 2000,*/ positionClass: 'toast-center-center', closeButton: true });
  }

  showWarning(message, title) {
    this.toastr.warning(message, title, { enableHtml: true, disableTimeOut: true, /*extendedTimeOut: 2000,*/ positionClass: 'toast-center-center', closeButton: true });
  }

}
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found.component'
import { PageNodeComponent } from './question/page-node.component';
import { LoginComponent } from './login/login.component';
import { PolicyGuard } from './policy.guard';
import { CarrierComponent } from './carrier/carrier.component';
import { FaqComponent } from './common/faq.component';
import { CommonFaqComponent } from './common/common-faq.component';
import { FeedbackComponent } from './common/feedback.component';
import { PrintComponent } from './common/print.component';
import { BannerComponent } from './layout/banner.component';
import { AdditionalHelpComponent } from './common/additionalhelp/additionalhelp.component';

const routes: Routes = [
  { path: 'question/:sectionTreeGuid/:pageNodeTypeCd', component: PageNodeComponent, canActivate: [PolicyGuard], },
  { path: 'question/:sectionTreeGuid/:pageNodeTypeCd/:objectNumber', component: PageNodeComponent, canActivate: [PolicyGuard], },
  { path: 'startTree/:sectionTreeTypeCd/:objectNumber', component: PageNodeComponent, canActivate: [PolicyGuard], },
  { path: 'startModule/:moduleTypeCd', component: PageNodeComponent, canActivate: [PolicyGuard], runGuardsAndResolvers: 'always' },
  { path: 'question', component: PageNodeComponent, canActivate: [PolicyGuard], },
  { path: 'Help/PrintPage/:pageNodeType', component: PrintComponent },
  { path: 'login', component: LoginComponent },
  { path: 'Security/LogIn', component: LoginComponent },
  { path: 'login/:carrierId', component: LoginComponent },
  { path: 'Security/LogOut', component: LoginComponent },
  { path: 'carriers', component: CarrierComponent },
  { path: 'faq/:id/:type', component: FaqComponent },
  { path: 'commonfaq', component: CommonFaqComponent },
  { path: 'feedback', component: FeedbackComponent },
  { path: '', redirectTo: '/question', pathMatch: 'full' },
  { path: 'error', component: PageNotFoundComponent },
  { path: '**', component: PageNotFoundComponent },
  { path: 'banner', component: BannerComponent },
  { path: 'additionalhelp', component: AdditionalHelpComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {

    onSameUrlNavigation: 'reload',
  })],
  // imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}

import { Host, Inject, Injectable } from '@angular/core';
import { of, Observable, throwError } from 'rxjs';
import { map, catchError, retry, share, shareReplay, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { PageNodeModel, RecordIdentifier } from '@shared/models/page-node-model';
import { StorageKey, StorageService } from '@shared/services/storage.service';
import { Config } from '../../app/config';
import { WINDOW } from '@shared/windows.provider';

export interface PageContent {
  CarrierLogoFile?: string;
  CarrierSpokenName?: string;
  CarrierId: number;
  Language?: string;
  PolicyIdentifierName?: string;
  PrivacyPolicyLink?: string;
  BannerText?: string;
  CallCenterRepTitle?: string;
  CallCenterPhone?: string;
  CallCenterHours?: string;

}

// export interface RequestCommonParams {
//   jwtToken: string;
//   jwtExpirationDate: Date;
//   interviewId: number;
// }

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private httpClient: HttpClient, private storage: StorageService, @Inject(WINDOW) private window: Window) {
  }

  login(userName: string, password: string, carrierId: string) {
    return this.httpClient.post<any>(`${Config.app.apiUrl}/login/web`, { userName: userName, password: password, carrierId: carrierId })
      .pipe(
        tap(
          res => this.setSession(res)),
        shareReplay()
      );
  }

  private setSession(authResult) {
    if (authResult) this.storage.setItem(StorageKey.loginResponse, JSON.stringify(authResult));
    if (authResult?.AccessToken) this.storage.setItem(StorageKey.jwtToken, authResult.AccessToken);
    if (authResult?.ExpiresIn) this.storage.setItem(StorageKey.jwtExpirationDate, authResult.ExpiresIn);
    if (authResult?.StartPageInfo.InterviewId) this.storage.setItem(StorageKey.interviewId, authResult.StartPageInfo.InterviewId);
    if (authResult?.RecordIdentifier) this.storage.setItem(StorageKey.recordIdentifier, JSON.stringify(authResult.RecordIdentifier));
    //if (authResult?.Policy) this.storage.setItem(StorageKey.policy, JSON.stringify(authResult.Policy));
  }

  logout() {
    this.storage.removeItem(StorageKey.loginResponse);
    this.storage.removeItem(StorageKey.jwtToken);
    this.storage.removeItem(StorageKey.jwtExpirationDate);
    this.storage.removeItem(StorageKey.personSpokenTo);
    this.storage.removeItem(StorageKey.payLoadJson);
  }

  public isLoggedIn() {
    const jwt = this.storage.getItem(StorageKey.jwtToken);
    const now = new Date();
    let loggedIn: boolean = false;
    if (jwt) {
      loggedIn = true;
      //now <= this.getExpiration();
    }
    return loggedIn;
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getExpiration() {
    const expiration = this.storage.getItem(StorageKey.jwtExpirationDate);
    //const expiresAt = JSON.parse(expiration);
    return new Date(expiration);
  }

  getAuthorizationToken() {
    return this.storage.getItem(StorageKey.jwtToken);
  }

  getHostname(): string {
    return this.window.location.hostname;
  }

  getLoginPage(carrierId: string) {
    let url;

    if (carrierId && +carrierId >= 0 && Config.app.islocal === true)
      url = `${Config.app.apiUrl}/login/web/${carrierId}`;
    else {
      let hostName = this.getHostname();
      let queryParam = hostName.replace(`${Config.app.domainPostfix}.autopolicyupdate`, '.autopolicyupdate');
      queryParam = queryParam.replace(`.com`, '');
      url = `${Config.app.apiUrl}/login/web?domainUrl=${queryParam}`;
    }

    return this.httpClient.get<PageContent>(url)
      .pipe(
        tap(res => {

          this.storage.setItem(StorageKey.logoFile, res.CarrierLogoFile);

        }),
        shareReplay()
      );

  }


}

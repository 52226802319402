import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { CarrierService } from '@shared/carrier.service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import { Observable, forkJoin, combineLatest } from 'rxjs';

@Component({
  selector: 'app-carrier',
  templateUrl: './carrier.component.html',
  styleUrls: ['./carrier.component.scss']
})
export class CarrierComponent implements OnInit {
  form: FormGroup;
  carriers$: Observable<any>;
  resetPolicyMessage: string;


  constructor(private router: Router, private carrierService: CarrierService) {
    this.form = new FormGroup({
      carrierId: new FormControl()
    });
  }

  ngOnInit(): void {
    this.carriers$ = this.carrierService.getCarriers();
  }

  onSubmit($event, form) {
    if (form.valid) {
      this.router.navigate(["/login/" + form.controls["carrierId"].value]);
    }
  }

  resetTestPolicies() {
    var carrierId = this.form.controls["carrierId"].value;
    this.carrierService.resetTestPolicies(carrierId).subscribe(
      res => this.resetPolicyMessage = JSON.stringify(res),
      err => this.resetPolicyMessage = err,
    );
  }

}

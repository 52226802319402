import { Component, OnInit } from '@angular/core';
import { StorageKey, StorageService } from '@shared/services/storage.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  banner: any;

  constructor(private storage: StorageService) { }

  ngOnInit(): void {
  }

  get bannerText() { return this.storage.getItem(StorageKey.bannerText); }
}

import { Injectable } from '@angular/core';
import { of, Observable, throwError } from 'rxjs';
import { map, catchError, retry, tap, shareReplay } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Config } from '../app/config';
import { PageNodeModel, RecordIdentifier } from '@shared/models/page-node-model';
import { StorageKey, StorageService } from './services/storage.service';


export interface UtilityResponse {
  QuickTransform?: string;
}

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private httpClient: HttpClient, private storage: StorageService) { }

  getBannerAlert(): Observable<any> {
    return this.httpClient.get<any>('api/banner');
  }

  getTransformString(stringToTransform: string, recordIdentifier: RecordIdentifier): Observable<UtilityResponse> {

    recordIdentifier = JSON.parse(this.storage.getItem(StorageKey.recordIdentifier));
    const http$ = this.httpClient.post<UtilityResponse>(encodeURI(`${Config.app.apiUrl}/utility/QuickTransform?stringToTransform=${stringToTransform}`), recordIdentifier)
      .pipe(
        //tap(() => console.log("/utility/QuickTransform?string")),
        shareReplay(),
      )
    return http$;
  }
}


import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { CollectorList } from '@shared/models/page-node-model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-question-form-collector',
  host: { '[class]': "{newline: isNewLine, 'flex-width': isFlexWidth}" },
  templateUrl: './question-form-collector.component.html',
  styleUrls: ['./question-form-collector.component.scss']
})
export class QuestionFormCollectorComponent implements OnInit, OnChanges {

  @Input() collector: CollectorList;
  @Input() form: FormGroup;
  formControl: AbstractControl;
  dateParam: any;
  condition:boolean;
  constructor(private datePipe: DatePipe) {
  }

  ngOnInit(): void {
    this.formControl = this.form?.controls[this.collector.HtmlName];
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  get isNewLine() {
    return this.collector.FormInputTypeCode !== 'ActionButton';
  }

  get isFlexWidth() {
    return this.collector.FormInputTypeCode !== 'Radio' && this.collector.FormInputTypeCode !== 'RadioInline' && this.collector.FormInputTypeCode !== 'CheckBox';
  }


  get isInvalid() {
    if (!this.formControl) { return null; }
    return (this.formControl.dirty || this.formControl.touched) && this.formControl.invalid;
  }

  get hasValue() {
    if (!this.formControl) { return null; }
    return this.formControl.value?.length > 0;
  }
  get errorMessage() {
    if (!this.formControl) { return null; }

    const errors = this.formControl.errors;
    // { "maxlength": { "requiredLength": 10, "actualLength": 21 }, "pattern": { "requiredPattern": "^([- .A-Za-z0-9])*$", "actualValue": "xxx @ afdasf sdaf sa" } }
    if (errors?.required) { return /*this.collector.Label + */ "A response is required"; }
    if (errors?.maxlength) { return errors.maxlength.requiredLength + " characters are allowed"; }
    if (errors?.pattern) {
      let msg;
      for (let v of this.collector.ValidationRuleList) {
        if (errors.pattern.requiredPattern.includes(v.Regex)) {
          msg = v.Message;
          break;
        }
      }
      return msg;
    }
    if (errors?.dateMinimum) { return "Date must be after " + errors.dateMinimum.required; }
    if (errors?.dateMaximum) { return "Date must be before " + errors.dateMaximum.required; }
    return this.formControl.errors;
  }

  clearValue() {
    this.formControl?.setValue(undefined, { emitEvent: false });
  }

  sort(array): any {
    return array.sort((a, b) => a.SortOrder - b.SortOrder);
  }

  onButtonClick(value: string) {
    this.formControl?.setValue(value, { emitEvent: false });
    this.collector.DefaultValue = value;
  }

  
  getErrorMessages(array):any{
    let result = array.map(a => a.Message);

    let message =  result.join(". ");

    message = message.replace('"."','DOT');
    message = message.replace('" "','space');
    message = message.replace('"-"','hyphen');
    message = message.replace('A response is required','');

    return message ;
  }

  dateTransformKeyDown($event): void{    
    this.dateParam = this.datePipe.transform($event.srcElement.value, 'MMMM-dd-yyyy') == null ? "" : "  Entered date is " + this.datePipe.transform($event.srcElement.value, 'MMMM-dd-yyyy');
    if($event.code == "ArrowUp") {
    this.condition = false;
    }
    if($event.code == "ArrowDown") {
      this.condition = false;
    }
  }
  dateTransformChange($event): void{
    this.dateParam = this.datePipe.transform($event.srcElement.value, 'MMMM-dd-yyyy') == null ? "" : "Entered date is " + this.datePipe.transform($event.srcElement.value, 'MMMM-dd-yyyy');
    this.condition = true
  }
  dateSelected(): any{    
    return 'selected';
  }
  dateTransformClick($event): void{    
    if($event.buttons == 1) {
    this.condition = true;
    }
    this.dateParam = this.datePipe.transform($event.srcElement.value, 'MMMM-dd-yyyy') == null ? "" : "  Entered date is " + this.datePipe.transform($event.srcElement.value, 'MMMM-dd-yyyy');
  }

}

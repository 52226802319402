import { Component } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import { Theme } from '@shared/themes/theme';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  loading: boolean = false;

  title = 'Verisk Analytics';
  icon = "favicon.ico";

  constructor(private router: Router) {
    // this.router.onSameUrlNavigation = 'reload';
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    router.events.subscribe((routerEvent: Event) => {
      this.checkRouterEvent(routerEvent);
    });


    const themeConfig: Theme = {
      name: 'versik-blue',
      properties: {
        '--background': '#f6f7f9',
        '--on-background': '#000',
        '--primary': '#006BA6',
        '--on-primary': '#fff',
        '--secondary': '#a8a8a8',
        '--on-secondary': '#fff',
        '--surface': '#fff',
        '--on-surface': '#000',
        '--error': '#E74E3C',
        '--on-error': '#fff',
        '--primary-color': 'red',
        '--secondary-color': 'yellow'
      }
    };

    //this.changeTheme(themeConfig); // Set default theme
  }

  checkRouterEvent(routerEvent: Event): void {
    if (routerEvent instanceof NavigationStart) {
      this.loading = true;
    }

    if (routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError) {
      this.loading = false;
      //TODO: See the top of the page once lands on the new page.
      window.scroll(0,0);
    }
  }

  //dynamic theme variables 
  changeTheme(themeConfig: Theme) {
    for (let [key, value] of Object.entries(themeConfig?.properties)) {
      console.log(key + ':' + value);
      document.documentElement.style.setProperty(key, value as string);
    }
  }

}



import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import { HttpProgressState, HttpStateService, IHttpState } from '@shared/services/http-state.service';

@Injectable({
  providedIn: 'root'
})
export class NoopInterceptorService implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    return next.handle(req);
  }
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, private auth: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    // if (req.url.includes('question') && !this.auth.isLoggedIn())
    //   this.router.navigate(['login']);

    const authToken = this.auth.getAuthorizationToken();
    if (authToken) {
      req = req.clone({
        headers: req.headers.set("Authorization",
          "Bearer " + authToken)
      });
    }


    //handle httpResponse 
    return next.handle(req).pipe(
      tap(
        (evt) => {
          if (evt instanceof HttpResponse) {
            //console.log(evt);
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status == 401) {               
              this.auth.logout();              
              this.router.navigate(['login']);
            } else if (err.status >= 500) {
              if (req.url.indexOf('login') < 0) {
                //TODO: hack 500 error code from failed login
                console.log(JSON.stringify(err));
                this.router.navigate(['error']);
              }
            } else {
            }
          }
        }
      ));
  }
}


@Injectable()
export class EnsureHttpsInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // clone request and replace 'http://' with 'https://' at the same time
    const secureReq = req.clone({
      url: req.url.replace('http://', 'https://')
    });
    return next.handle(secureReq);
  }
}

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  readonly MAX_RETRY: number = 3;

  private exceptions: string[] = [
    'login'
  ];

  constructor(private router: Router,
    private httpStateService: HttpStateService) {

  }

  /**
   * Intercepts all requests
   * - in case of an error (network errors included) it repeats a request 3 times
   * - all other error can be handled an error specific case
   * and redirects into specific error pages if necessary
   *
   * There is an exception list for specific URL patterns that we don't want the application to act
   * automatically
   * 
   * The interceptor also reports back to the httpStateService when a certain requests started and ended 
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.httpStateService.state.next({
      url: request.url,
      state: HttpProgressState.start
    });


    if (!this.exceptions.every((term: string) => request.url.indexOf(term) === -1)) {
      return next.handle(request).pipe(
        tap((response: any) => { }, (error) => { }),
        finalize(() => {
          this.httpStateService.state.next({
            url: request.url,
            state: HttpProgressState.end
          });
        })
      );
    }

    return next.handle(request).pipe(
      retryWhen(
        error => {
          let retries = 0;
          return error.pipe(
            take(this.MAX_RETRY),
            delay(3000),
            tap((response: any) => {
              if (response.status < 500) {
                throw response;
              }
              if (++retries >= this.MAX_RETRY) {
                this.router.navigate(['error']);
              }

              // ...logic based on response type
              // i.e redirect on 403
              // or feed the error on a toaster etc
            })
          );
        }
      ),
      finalize(() => {
        this.httpStateService.state.next({
          url: request.url,
          state: HttpProgressState.end
        });
      }));
  }
}

/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { catchError, delay, finalize, retryWhen, take, tap } from 'rxjs/operators';
/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  //{ provide: HTTP_INTERCEPTORS, useClass: NoopInterceptorService, multi: true },
  //{ provide: HTTP_INTERCEPTORS, useClass: EnsureHttpsInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
];

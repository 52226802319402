import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppConfigService } from './app-config.service';
import { throwIfAlreadyLoaded } from './module-import-guard';
export function appInitializer(
  appConfigService: AppConfigService
) {
  return async () => {
    await appConfigService.loadAppConfig();
  };
}

/**
 * A factory function to load/create/provide the application configuration
 * This is loaded from the web host
 * @param appConfigService the application configuration data service
 */
export function getAppConfig(appConfigService: AppConfigService) {
  return () => appConfigService.loadAppConfig();
}





// imports: imports the module's exports. which is usually declarables and providers
// in our case the spinner has no providers.
//
// exports: exports modules AND components/directives/pipes that other modules may want to use
@NgModule({
  imports: [CommonModule, FormsModule, RouterModule],
  exports: [CommonModule, FormsModule, RouterModule]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}

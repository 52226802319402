import { Injectable } from '@angular/core';
import { of, Observable, throwError } from 'rxjs';
import { map, catchError, retry, mergeMap, tap, shareReplay, retryWhen, delay, take } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PageNodeModel, RecordIdentifier } from '@shared/models/page-node-model';
import { StorageKey, StorageService } from './services/storage.service';
import { FormControl, FormGroup } from '@angular/forms';
import { formatDate } from '@angular/common';
import { Config } from '../app/config';

@Injectable({
  providedIn: 'root'
})
export class PageNodeService {
  constructor(private httpClient: HttpClient, private storage: StorageService) { }

  startModule(moduleTypeCd) {
    var interviewId = this.storage.getItem(StorageKey.interviewId);
    var recordIdentifier = JSON.parse(this.storage.getItem(StorageKey.recordIdentifier));
    if (!interviewId || !recordIdentifier) throw ({ status: 401, errorMessage: 'require login' });

    const request = this.createQuestionsRequestBody("GetQuestions", recordIdentifier, null, null, null, null, interviewId, null, null, moduleTypeCd);
    return this.postQuestions('web', request);
  }

  startTree(sectionTreeTypeCd, objectNumber: number) {
    var interviewId = this.storage.getItem(StorageKey.interviewId);
    var recordIdentifier = JSON.parse(this.storage.getItem(StorageKey.recordIdentifier));
    if (!interviewId || !recordIdentifier) throw ({ status: 401, errorMessage: 'require login' });
    if (objectNumber) recordIdentifier.ObjectNumber = +objectNumber;

    const request = this.createQuestionsRequestBody("GetQuestions", recordIdentifier, null, null, null, objectNumber, interviewId, null, sectionTreeTypeCd, null);
    return this.postQuestions('web', request);
  }

  getPage(sectionTreeGuid, pageNodeTypeCd, pageNumber, objectNumber): Observable<PageNodeModel> {
    const interviewId = this.storage.getItem(StorageKey.interviewId);
    var recordIdentifier = JSON.parse(this.storage.getItem(StorageKey.recordIdentifier));
    if (!interviewId || !recordIdentifier) throw ({ status: 401, errorMessage: 'require login' });
    if (objectNumber) recordIdentifier.ObjectNumber = +objectNumber;
    var anwsers = null;
    const request = this.createQuestionsRequestBody("GetQuestions", recordIdentifier, sectionTreeGuid, pageNodeTypeCd, pageNumber, objectNumber, interviewId, anwsers, null, null);
    return this.postQuestions('web', request, null);
  }

  getPrintPage(pageNodeTypeCd): Observable<PageNodeModel> {
    const interviewId = this.storage.getItem(StorageKey.interviewId);
    var recordIdentifier = JSON.parse(this.storage.getItem(StorageKey.recordIdentifier));
    if (!interviewId || !recordIdentifier) throw ({ status: 401, errorMessage: 'require login' });
    const request = this.createQuestionsRequestBody("Print", recordIdentifier, null, pageNodeTypeCd, null, null, interviewId, null, null, null);
    return this.postQuestions('web', request, null);
  }


  postPage(sectionTreeGuid, pageNodeTypeCd, pageNumber, objectNumber, $event): Observable<PageNodeModel> {
    let formGroup = $event.form;
    let submitter = $event.submitter;
    let collectorTypeDict = $event.collectorTypeDict;

    const interviewId = this.storage.getItem(StorageKey.interviewId);
    var recordIdentifier = JSON.parse(this.storage.getItem(StorageKey.recordIdentifier));
    if (objectNumber) recordIdentifier.ObjectNumber = +objectNumber;
    var anwsers = formGroup ? this.convertFormGroup(formGroup.controls, []) : null;
    anwsers = this.transformFormData(anwsers, submitter, collectorTypeDict);

    const request = this.createQuestionsRequestBody("PostQuestions", recordIdentifier, sectionTreeGuid, pageNodeTypeCd, pageNumber, objectNumber, interviewId, anwsers, null, null);
    return this.postQuestions('web', request, null);
  }

  private transformFormData(answers, submitter, collectorTypeDict): Answer[] {
    //Tel type behavior in CCModel
    let tels = collectorTypeDict.tels;
    tels.forEach(collector => {
      const fc = answers.find(ans => ans.key === collector.HtmlName);
      if (!fc) return;
      fc.value = fc?.value?.replace(/["() -]/g, "").slice(0, 10);
    });

    //date type behavior in ccModel
    let dates = collectorTypeDict.dates;
    dates.forEach(collector => {
      const fc = answers.find(ans => ans.key === collector.HtmlName);
      if (!fc) return;
      try {
        fc.value = formatDate(fc.value, 'MM/dd/yyyy', 'en');
      }
      catch (error) {
        //TODO: backend needs to support null of date type input in future enhancement
        //fc.value = null;
      }
    });

    //hack ActionButton, RadioButton behavior in CCModel
    if (submitter === "continueSubmit") {
      answers.push({ key: "continueSubmit", value: 'Continue' });
    } else if (submitter === "ButtonCancelTree") {
      answers.push({ key: "ButtonCancelTree", value: "ButtonCancelTree" });
    } else {
      let actionButtons = collectorTypeDict.actionButtons;
      actionButtons.forEach(collector => {
        //TODO: hacked solution. Add triggered ActionButton into FormGroup to be compatible with CCModel FormCollection
        if (collector.HtmlName === submitter) {
          answers.find(ans => ans.key === collector.HtmlName).value = collector.Label;
        }
        else {
          answers = answers.filter((value, index, arr) => {
            return value.key !== collector.HtmlName;
          });
        }
      });

      //TODO: obsolete. send all collectors as ususal but handle CCModel IsIgnoreCollectorValidationRuleError at server to bypass validation flow 
      //
      // collectorTypeDict.radioButtons.forEach(collector => {
      //   let answer = answers.find(ans => ans.key === collector.HtmlName)
      //   if (answer?.value?.length <= 0) {
      //     answers = answers.filter((value, index, arr) => {
      //       return value.key !== collector.HtmlName;
      //     });
      //   }
      // });

    }

    return answers;
  }

  private convertFormGroup(formGroupControls, answers: Answer[]): Answer[] {
    for (let [key, value] of Object.entries(formGroupControls)) {
      if (value instanceof FormControl) {
        console.log(key + ':' + value.value);
        answers.push({ key: key, value: value.value });
      } else if (value instanceof FormGroup) {
        this.convertFormGroup(value.controls, answers);
      }
    }
    return answers;
  }

  private postQuestions(applicationType: string, request: QuestionRequest, observe?: 'body', reportProgress?: boolean): Observable<PageNodeModel> {

    const http$ = this.httpClient.post<PageNodeModel>(`${Config.app.apiUrl}/Question/${applicationType}`, request)
      .pipe(
        tap(() => console.log("HTTP request executed")),
        map((page, index) => {
          this.storage.setItem(StorageKey.recordIdentifier, JSON.stringify(page.RecordIdentifier));
          return page;
        }),
        shareReplay(),
      )
    return http$;
  }

  public createQuestionsRequestBody(requestType, recordIdentifier: RecordIdentifier, sectionTreeGuid, pageNodeTypeCd, pageNumber, objectNumber, interviewId, answers, sectionTreeTypeCd, moduleTypeCd): any {
    const request: QuestionRequest = {
      header: {
        authorization: {
          carrierId: recordIdentifier.CarrierId,
          qpcIdNum: recordIdentifier.QpcIdNum,
          applicationType: 'web',
        },
        quoteback: "8-policyId-DateTime"
      },
      body: {
        requestType: requestType,
        sectionTreeGuid: sectionTreeGuid,
        pageNodeTypeCd: pageNodeTypeCd,
        interviewId: interviewId,
        pageNumber: pageNumber,
        answers: answers,
        recordIdentifier: recordIdentifier,
        sectionTreeTypeCd: sectionTreeTypeCd,
        moduleTypeCd: moduleTypeCd
      }
    }
    return request;
  }
}

export interface QuestionRequest {
  header?: Header;
  body?: Body;
}


export interface Header {
  authorization?: Authorization;
  quoteback?: string;
}


export interface Authorization {
  carrierId: number;
  qpcIdNum?: string;
  applicationType?: string;
}

export interface Body {
  requestType: string;
  sectionTreeGuid?: string;
  sectionTreeTypeCd?: string;
  pageNodeTypeCd?: string;
  interviewId: number;
  pageNumber?: number;
  answers?: Array<Answer>;
  recordIdentifier?: RecordIdentifier;
  moduleTypeCd?: string;
}



export interface Answer {
  key?: string;
  value?: string;
}

// export interface RecordIdentifier {
//   qpcIdNum?: string;
//   batchId: number;
//   riskStateCd?: string;
//   carrierId: number;
//   programId: number;
//   ruleGroupId: number;
//   exceptionGroupId: number;
//   applicationTypeCd?: string;
//   customizationGroupMembershipArray?: Array<number>;
//   customizationGroupMembershipString?: string;
//   parentObjectNumber?: number;
//   objectNumber?: number;
// }
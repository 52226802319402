import { Injectable } from '@angular/core';
import { of, Observable, throwError } from 'rxjs';
import { map, catchError, retry, share, shareReplay, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { Config } from '../app/config';

@Injectable({
  providedIn: 'root'
})

export class FaqService {
  constructor(private httpClient: HttpClient) { }

  getFaqs(faqType:string, carrierId:number): Observable<any> {
    return this.httpClient.get<HelpFAQModel>(`${Config.app.apiUrl}/help/faq/web/${carrierId}?questionBlockType=${faqType}`);
  }
}

export interface HelpFAQModel {
  QuestionBlockTypeCode: string;
  IsFAQAvailable: boolean;
  QuestionBlockHelpFAQ?: Array<QuestionBlockHelpFAQ>;
}

export interface QuestionBlockHelpFAQ{
  questionBlockHelpFAQId: number;
  questionBlockTypeCd: string;
  customizationGroupId: number;
  verId: number;
  helpFAQId: number;
  sortOrder: number;
  }

import { Component, OnDestroy, OnInit, HostListener, ApplicationRef, NgZone, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { formatDate, Location } from '@angular/common';
import { Observable, forkJoin, combineLatest, interval, race } from 'rxjs';
import { PageNodeService } from '../../shared/page-node.service';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { LayoutService } from '../layout/layout.service';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute, Event, Params } from "@angular/router";
import { filter, map, first, mergeMap, take, mapTo } from 'rxjs/operators';
import { StorageKey, StorageService } from '@shared/services/storage.service';
import { PageNodeModel } from '@shared/models/page-node-model';

import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-page-node',
  templateUrl: './page-node.component.html',
  styleUrls: ['./page-node.component.scss']
})
export class PageNodeComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  page$: Observable<any>;
  page: PageNodeModel;
  sPage: Subscription;
  objectNumber: number | null;
  backendError: string;
  currentURL: string;
  message:string;
  errorPopup: boolean = false;
  popupCloseClicked: boolean = false;
  @ViewChildren("autoTrigger") modalButton: QueryList<ElementRef>;
  carrierId = this.storage.getItem(StorageKey.carrierId);
  getPage(): Observable<PageNodeModel> {
    return combineLatest([
      this.activatedRoute.params,
      this.activatedRoute.queryParams,
    ])
      .pipe(
        mergeMap(val => {
          this.objectNumber = +val[0]?.objectNumber;
          let sectionTreeGuid = val[0]?.sectionTreeGuid;
          let pageNodeTypeCd = val[0]?.pageNodeTypeCd;
          let sectionTreeTypeCd = val[0]?.sectionTreeTypeCd;
          let moduleTypeCd = val[0]?.moduleTypeCd;

          if (sectionTreeGuid && pageNodeTypeCd) {
            if (sectionTreeGuid === this.page?.SectionTreeGuid && pageNodeTypeCd === this.page?.TypeCode) return;
            return this.pageService.getPage(sectionTreeGuid, pageNodeTypeCd, null, this.objectNumber);
          } else if (sectionTreeTypeCd && this.objectNumber) {
            return this.pageService.startTree(sectionTreeTypeCd, this.objectNumber);
          } else if (moduleTypeCd) {
            return this.pageService.startModule(val[0]?.moduleTypeCd);
          } else {
            var res = JSON.parse(this.storage.getItem(StorageKey.loginResponse));
            if (res)
              return this.pageService.getPage(res?.StartPageInfo.SectionTreeGuid, res?.StartPageInfo.PageNodeTypeCode, null, null);
            else throw ({ status: 401, errorMessage: 'no login response in storage' })
          }
        })
      );


  }

  constructor(private pageService: PageNodeService, private layoutService: LayoutService,
    private location: Location, private activatedRoute: ActivatedRoute,
    private router: Router, private storage: StorageService, private toastr: ToastrService) {

    //console.log('running through PageNode Com constructor');

    // this.router.events.subscribe(() => {
    //   zone.run(() =>
    //     setTimeout(() => {
    //       this.applicationRef.tick();
    //     }, 0)
    //   );
    // });

    this.page$ = this.getPage();
    this.page$?.subscribe(
      res => {
        this.page = res;
      },
      error => {
        if (error.status === 401) this.router.navigate(['login']);
        else this.router.navigate(['error']);

      }
    );

    // this.location.onUrlChange((url, state) => {
    //   if (state) {
    //     console.log('location url change', url, state);
    //   }
    // });

    const sLocation = this.location.subscribe(
      ((evt: PopStateEvent) => {
        // console.log("locaton subscrible OnNext")
        // console.log(evt);
        // console.log(this.location.path());

        this.page$ = this.getPage();
        this.page$?.subscribe(
          res => {
            this.page = res;
          },
          error => {
            if (error.status === 401) this.router.navigate(['login']);
            // else this.router.navigate(['error']);
          }
        );

        //this.router.navigateByUrl(this.router.url);
        // const eventSubscription = this.router.events.subscribe((event: Event) => {
        //   if (event instanceof NavigationEnd) {
        //     eventSubscription.unsubscribe();
        //   }
        // });
      }),
      (ex => {
        console.log("Error occured postate event")
        console.log(ex);
      })
    );
    this.subscription.add(sLocation);

    // const sNav = this.router.events
    //   .pipe(
    //     // filter(
    //     //   (event: NavigationStart) => event.navigationTrigger === 'popstate'
    //     // )
    //   )
    //   .subscribe(e => {
    //     if (e instanceof NavigationEnd) {
    //       // console.log('Router Navigation type: ', e.url);
    //       //this.router.navigateByUrl(this.router.url);
    //       //this.location.go(this.router.url);
    //     }
    //   });
    // this.subscription.add(sNav);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  sortMenuModule(array): any {
    return array.sort((a, b) => a.ModuleOrder - b.ModuleOrder);
  }

  onSubmit($event: any, sectionTreeGuid, typeCode) {
    this.page$ = this.pageService.postPage(sectionTreeGuid, typeCode, null, this.objectNumber, $event)
      .pipe(
        take(1)
      );

    var sPage = this.page$
      .subscribe(
        res => {
          if (!res) return;

          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.navigated = false;

          this.page = res;
          this.objectNumber = res.RecordIdentifier.ObjectNumber;


          if($event.collectorTypeDict?.actionButtons) {
              const submitterLabel = $event.collectorTypeDict?.actionButtons.find(f => f.HtmlName == $event.submitter)?.Label;
              if(submitterLabel){
                if(submitterLabel !== 'continueSubmit' && submitterLabel !== 'ButtonCancelTree' && submitterLabel !== 'Cancel' && (this.carrierId == "104" || this.carrierId=="105")) 
                  {                    
                    this.showAlert(submitterLabel + ' Successful',false);  
                  }                
                  else if(submitterLabel.toLowerCase() === 'Add Odometer'.toLowerCase() || submitterLabel.toLowerCase() === 'Delete Odometer'.toLowerCase() || submitterLabel.toLowerCase() === 'Save Odometer'.toLowerCase()) 
                  {             
                      this.showAlert(submitterLabel + ' Successful',true);   
                  } 
              }
          }

          if (res.RecordIdentifier.ObjectNumber) {
            this.location.go(`question/${res.SectionTreeGuid}/${res.TypeCode}/${res.RecordIdentifier.ObjectNumber}`);
            //this.router.navigate([`question/${res.SectionTreeGuid}/${res.TypeCode}/${res.RecordIdentifier.ObjectNumber}`])
          }
          else {
            this.location.go(`question/${res.SectionTreeGuid}/${res.TypeCode}`);
            //this.router.navigate([`question/${res.SectionTreeGuid}/${res.TypeCode}`])
          }
        },
        res => {
          if (res.status >= 500)
            console.error('submit error: ' + res.message);
            else if (res.status >= 400  && ((this.carrierId == "104" || this.carrierId=="105") || (this.page.TypeCode.toLowerCase() === 'OdometerAdd'.toLowerCase() || this.page.TypeCode.toLowerCase()  === 'OdometerEdit'.toLowerCase()  || this.page.TypeCode.toLowerCase()  === 'OdometerEditDelete'.toLowerCase() )))
            {
              this.showAlert(res.error[0].Error,true);
            }          
          else  
          {
            this.showWarning(res.error[0].Error, null);
          }            
        },
        () => {
          //----------ISOQPCMP-15142-USAA Accessibility UI 4 - 26 When continue button is pressed there is no indication to SR----//
          const logoElement = document.querySelector('img[alt="Logo"]') as HTMLElement;
          if (logoElement && !this.message) {
            logoElement.parentElement.tabIndex = 0;
            logoElement.parentElement?.focus();  
          }
          //----------------------------------------------------------------------------------------------------------------------//
          console.log('submit complete');
        }
      );
    this.subscription.add(sPage);
  }

  /**
  * left sidebar in layout
  */
  getClasses() {
    const classes = {
      'pinned-sidebar': this.layoutService.getSidebarStat().isSidebarPinned,
      'toggeled-sidebar': this.layoutService.getSidebarStat().isSidebarToggeled
    }
    return classes;
  }
  toggleSidebar() {
    this.layoutService.toggleSidebar();
  }


  toggleSidebarPin() {
    this.layoutService.toggleSidebarPin();
  }

  showModal() {
    console.log('show popup');
  }


  /**
   * question menu sidebar on the right
   */
  isQuestionSidebarPinned: boolean = false;
  toggleQuestionSidebarPin() {
    this.isQuestionSidebarPinned = !this.isQuestionSidebarPinned;
  }

  getQuestionMenuClasses(menuItem) {
    const classes = {
      'completed': menuItem.isCompleted,
      'current': menuItem.isCurrentModule
    }
    return classes;
  }

  showSuccess(message, title) {
    this.toastr.success(message, title, { enableHtml: true });
  }

  showWarning(message, title) {
    this.toastr.warning(message, title, { enableHtml: true, /*disableTimeOut: true, extendedTimeOut: 2000,*/ positionClass: 'toast-center-center', closeButton: true });
    //this.toastr.warning(message, title, { enableHtml: true, disableTimeOut: true, positionClass: 'toast-center-center', closeButton: true });
  }

  showAlert(message, toEnable) 
  {
    this.errorPopup = toEnable; 
    this.message = message;
    let popupElement:HTMLElement = document.getElementById('autoTrigger') as HTMLElement;
    popupElement.click();
  }


  close() {
    this.popupCloseClicked = true;
    // check whether popup display for error or success/edit/delete trigger
    if (this.errorPopup === true) {
      const textElement = (document.querySelector('input[type="text"]') || document.querySelector('input[type="TextBox"]')) as HTMLElement;
      if (textElement) {
        textElement.tabIndex = 0;
        textElement.focus();  
        this.message = '';
      }
    } else {
      const logoElement = document.querySelector('img[alt="Logo"]') as HTMLElement;
      if (logoElement) {
        logoElement.parentElement.tabIndex = 0;
        logoElement.parentElement?.focus(); 
        this.message = ''; 
      }
    }
  }

  // @HostListener('window:popstate', ['$event'])
  // onBackButtonClick(event): void {
  //   const currentCode = '';
  //   if (currentCode)
  //     this.page$ = this.pageService.getPrevPage('');
  //   else this.location.back();
  // }

}
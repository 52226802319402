import { Directive, ElementRef,AfterViewInit } from '@angular/core';

@Directive({
  selector: '[autofocus]'
})
export class AutofocusDirective {
  constructor(private host: ElementRef) {}
//----------ISOQPCMP-15142 - USAA Accessibility UI 4 - 26 When continue button is pressed there is no indication to SR--//
  ngAfterViewInit() {    
    this.host.nativeElement.tabIndex  = 0;
    this.host.nativeElement.focus();
  }
}
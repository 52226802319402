import { enableProdMode, Injectable } from '@angular/core';
import { AppConfig } from '../models/application/appConfig.model';
declare const window: any;
@Injectable({ providedIn: 'root' })
export class AppConfigService {
  private config: AppConfig | undefined;

  getAppConfig(): AppConfig | undefined {
    return this.config;
  }

  loadAppConfig(): Promise<AppConfig> {
    return fetch('assets/config.json', {
      method: 'GET',
      /** need this to be included so that the cookies will send to the server
       * or else we will always get 302 and redirected to adfs for authentication */
      credentials: 'include'
    })
      .then(res => res.json())
      .then(({config}) => {         
        window.config = this.config = config;                    
        return config; 
      })
      .catch(err => {
        console.log(err);
      });
  }
}

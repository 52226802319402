import { Injectable } from '@angular/core';


export enum StorageKey {
  logoFile = 'logo',
  loginResponse = 'loginRes',
  jwtToken = 'id_token',
  jwtExpirationDate = 'expires_at',
  commonParams = "commonParams",
  interviewId = "interviewId",
  recordIdentifier = "RecordIdentifier",
  carrierId = "carrierId",
  carrierSpokenName = "CarrierSpokenName",

  policy = "policy",
 
  bannerText = "BannerText",
  callCenterRepTitle = "CallCenterRepTitle",
  callCenterPhone = "CallCenterPhone",
  callCenterHours = "CallCenterHours",

  // policy = "policy",
  personSpokenTo = "personSpokenTo",
  //TODO : ISUQPCB-11406 - Previous selection of the driver radio button is not showing as selected once user goes back to the page
  payLoadJson ="payLoadJson",
  carrierProfile="carrierProfile"

}

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  getItem(key: string) {
    return sessionStorage.getItem(key);
  }

  setItem(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }

  removeItem(key: string): void {
    return sessionStorage.removeItem(key);
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule,APP_INITIALIZER } from '@angular/core';
import { CommonModule, LocationStrategy, PathLocationStrategy, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { QuestionFormComponent } from './question/question-form.component';
import { PageNodeComponent } from './question/page-node.component';

import { PageNodeService } from '../shared/page-node.service';
import { QuestionFormCollectorComponent } from './question/question-form-collector.component';
import { SummaryItemComponent } from './question/summary/summary-item.component';
import { NavbarComponent } from './layout/navbar.component';
import { SidebarComponent } from './layout/sidebar.component';
import { FooterComponent } from './layout/footer.component';
import { QuestionMenuComponent } from './question/question-menu.component';
import { PageNotFoundComponent } from './page-not-found.component';
import { LoginComponent } from './login/login.component';


import { httpInterceptorProviders } from '../shared/security/interceptor-provider'
import { PhonePipe } from '@shared/utility/phone.pipe';
import { PhoneMaskDirective } from '@shared/utility/phone-mask.directive';
import { DomChangeDirective  } from '@shared/utility/domchange.directive';
import { FaqComponent } from './common/faq.component';
import { TopbarComponent } from './layout/topbar.component';
import { CarrierComponent } from './carrier/carrier.component';
import { SummaryItemBusinessUseComponent } from './question/summary/summary-item-business-use.component';
import { SummaryItemDriverComponent } from './question/summary/summary-item-driver.component';
import { FeedbackComponent } from './common/feedback.component';
import { BannerComponent } from './layout/banner.component';
import { SpinnerComponent } from './layout/spinner.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { CommonFaqComponent } from './common/common-faq.component';
import { WINDOW_PROVIDERS } from '../shared/windows.provider'
import { PrintComponent } from './common/print.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { AdditionalHelpComponent } from './common/additionalhelp/additionalhelp.component';
import { LogoutComponent } from './common/logout/logout.component';
import { CoreModule, appInitializer } from './core/core.module';
import { AppConfigService } from './core/app-config.service';
import {FormDirective} from '@shared/form.directive';
import { AutofocusDirective } from '@shared/autofocus.directive'
@NgModule({
  declarations: [
    AppComponent,
    QuestionFormComponent,
    PageNodeComponent,
    QuestionFormCollectorComponent,
    SummaryItemComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    QuestionMenuComponent,
    PageNotFoundComponent,
    LoginComponent,
    PhonePipe,
    PhoneMaskDirective,
    DomChangeDirective,
    FaqComponent,
    TopbarComponent,
    CarrierComponent,
    SummaryItemBusinessUseComponent,
    SummaryItemDriverComponent,
    FeedbackComponent,
    BannerComponent,
    SpinnerComponent,
    CommonFaqComponent,
    PrintComponent,
    AdditionalHelpComponent,
    LogoutComponent,
    FormDirective,
    AutofocusDirective    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    RecaptchaModule,  //this is the recaptcha main module
    RecaptchaFormsModule, //this is the module for form incase form validation
  ],
  providers: [DatePipe,  PageNodeService, httpInterceptorProviders, { provide: LocationStrategy, useClass: PathLocationStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      deps: [ AppConfigService],
      multi: true
    }, WINDOW_PROVIDERS],
  bootstrap: [AppComponent]
})
export class AppModule { }

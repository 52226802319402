import { Injectable } from '@angular/core';
import { of, Observable, throwError } from 'rxjs';
import { map, catchError, retry, share, shareReplay, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { Config } from '../app/config';
import { RecordIdentifier } from './models/page-node-model';
import { StorageKey, StorageService } from './services/storage.service';
import { FormControl, FormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root'
  })
  
  export class FeedbackService {
    constructor(private httpClient: HttpClient, private storage: StorageService) { }
    
    postPage( email: string, message: string, refUrl: string) {
    
      const carrierId = this.storage.getItem(StorageKey.carrierId);
      const interviewId = this.storage.getItem(StorageKey.interviewId);
      var recordIdentifier = JSON.parse(this.storage.getItem(StorageKey.recordIdentifier));
      var feedback : FeedbackModel;
      feedback = {Email: email, Message:message, URLReferrer:refUrl, PolicyNumber: recordIdentifier.QpcIdNum, IsSumbitted: false};

  
      const feedbackRequest = this.createFeedbackRequestBody( recordIdentifier, interviewId, feedback);
      
      return this.httpClient.post<FeedbackRequest>(`${Config.app.apiUrl}/help/feedback/web/${carrierId}`, feedbackRequest)
      .pipe(
        tap(() => console.log("HTTP request executed")),
        map((page, index) => {
          return page;
        }),
        shareReplay(),
      )
    }


    private convertFormGroup(formGroupControls, answers: FeedbackModel[]): FeedbackModel {
      for (let [key, value] of Object.entries(formGroupControls)) {
        if (value instanceof FormControl) {
          console.log(key + ':' + value.value);
          //answers.push({ key: key, value: value.value });
        } else if (value instanceof FormGroup) {
          this.convertFormGroup(value.controls, answers);
        }
      }
      return answers[0];
    }


    private createFeedbackRequestBody(recordIdentifier: RecordIdentifier, interviewId,feedback:FeedbackModel ): any {
      const request: FeedbackRequest = {
        header: {
          authorization: {
            carrierId: recordIdentifier.CarrierId,
            qpcIdNum: recordIdentifier.QpcIdNum,
            applicationType: 'web',
          },
          quoteback: "8-policyId-DateTime"
        },
        body: {
          recordIdentifier: recordIdentifier,
          feedback: feedback,
          interviewId: interviewId
        }
      }
      return request;
    }

  }





  export interface FeedbackModel {
    PolicyNumber: string;
    Email: string;
    Message: string;
    IsSumbitted:boolean;
    URLReferrer: string;
  }

  export interface FeedbackRequest {
    header?: Header;
    body?: Body;
  }
  
  export interface Header {
    authorization?: Authorization;
    quoteback?: string;
  }
  
  export interface Authorization {
    carrierId: number;
    qpcIdNum?: string;
    applicationType?: string;
  }
  
  export interface Body {

    recordIdentifier?: RecordIdentifier;
    feedback?: FeedbackModel;
    interviewId?:number;
  }
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PageNodeModel, CollectorList, OptionList, QuestionBlockList } from '@shared/models/page-node-model';
import { SummaryItemBase } from './summary-item-base'
import { StorageKey, StorageService } from '@shared/services/storage.service';

@Component({
  selector: 'app-summary-item',
  templateUrl: './summary-item.component.html',
  styleUrls: ['./summary-item.component.scss']
})
export class SummaryItemComponent extends SummaryItemBase implements OnInit {
  @Input() questionBlock: QuestionBlockList;
  @Input() form: FormGroup;
  iconEnum = IconType;
  cardTitle:any = [];
  constructor(private router: Router,public storage: StorageService) {
    super();
  }

  ngOnInit(): void {
    this.customizeColumn();
  }


  onSubmit($event, action, el) {     
    //TODO : ISUQPCB-11406 - Previous selection of the driver radio button is not showing as selected once user goes back to the page
    this.storage.setItem(StorageKey.payLoadJson, "{\""+this.questionBlock.QuestionBlockTypeCode +"\":" + JSON.stringify(this.form.getRawValue()) +"}"
    ); //browser back & refersh page functionality.
    //-------------------------------------------------------------------------
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([`startTree/${action.SectionTreeTypeCd}/${el.ObjectNumber}`]);
    $event.preventDefault();
  }

  customizeColumn() {
    if (this.questionBlock.IsSummary) {      
      switch (this.questionBlock.QuestionBlockTypeCode) {        
        case "GreetingWebIntroVehicleSummary":                  
          this.questionBlock.SummaryItemList.forEach(item => {
                this.cardTitle.push(item.Column1 + ' ' + item.Column2 + ' ' + item.Column3);
        });
        break;
        case "GreetingWebIntroDriverSummary":
          this.questionBlock.SummaryItemHeaderDict[2] = null;
          break;
        default:
          break;
      }
    }
  }
  getIconType(questionBlockTypeCode, el) {

    switch (questionBlockTypeCode) {
      case "GreetingWebIntroVehicleSummary":
      case "BusinessUseSummaryBlock":
        return IconType.Vehicle;
      case "GreetingWebIntroDriverSummary":
        //case "DriverSummaryList" "DriversSummaryListNotCovered": //no gender column
        if (el.Column2 === 'Male') return IconType.GenderMale;
        if (el.Column2 === 'Female') return IconType.GenderFemale;
      default:
        return undefined
    }

  }
}

export enum IconType {
  Vehicle = 0,
  GenderMale,
  GenderFemale,
}

<ng-container  *ngIf="form">
    <div role="form">
        <form focusInvalidInput (ngSubmit)="onSubmit($event, form, null)" [formGroup]="form" #parentForm="ngForm" class="mt-3">

            <div class="form-row ">
                <ng-container
                    *ngTemplateOutlet="page.TypeCode==='GreetingWebIntro'?tabBlockStyle:genericBlockStyle; context:{questionBlocks:page.QuestionBlockList}">
                </ng-container>
            </div>
            <div class="form-row mt-3 " aria-atomic="false" aria-live="polite">
                <!-- <button class="btn btn-primary" type="reset" (click)="parentForm.reset()">Reset</button> -->
                <button  class="btn btn-primary me-2 btn-sm" type="submit"
                    aria-label="Continue"
                    *ngIf="page.IsContinueButtonVisible" name="continueSubmit" id="continueSubmit"
                    (click)="submitter = 'continueSubmit'">Continue</button>
                <button  class="btn btn-primary me-2 btn-sm" type="submit"
                    *ngIf="page.IsCancelTreeButtonVisible" name="ButtonCancelTree" id="ButtonCancelTree"
                    aria-label="Cancel"
                    (click)="submitter = 'ButtonCancelTree'">Cancel</button>

                <!--ISOQPCMP-15110 - USAA Accessibility UI 3 - 23 remove "*Required Response" from site
                    <ng-container *ngIf="parentForm.submitted && form.invalid" class="">
                    <span class="error-block" class="text-danger">*Required Response</span>
                </ng-container> -->
            </div>


            <div *ngIf="payLoad" class="d-none form-row card">
                <strong>Saved the following values</strong><br>{{payLoad}}
            </div>
        </form>
    </div>
</ng-container>



<ng-template #singleBlockStyle let-i="index" let-q="questionBlock">
    <!-- Button trigger modal -->
    <div>

        <form focusInvalidInput (ngSubmit)="onSubmit($event, form.get(q.QuestionBlockTypeCode), q.CollectorList)"
            [formGroup]="form.get(q.QuestionBlockTypeCode)">
            <!-- Modal -->
            <div class="modal fade" [id]="'faqModal'+i" tabindex="-1" 
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h2 class="modal-title" [id]="'faqModalLabel'+i">Frequently Asked Questions</h2>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <app-faq [id]="carrierId" [type1]="q.QuestionBlockTypeCode"
                                (newItemEvent)="displayFaq(i, $event)"></app-faq>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card form-row">

                <div class="card-body" [id]="q.QuestionBlockTypeCode+'Tabpanel'">
                    <div>
                        <h1 aria-level="1" class="card-title question-title" *ngIf='q.Title'  #cardTitle>{{q.Title}}
                            <ng-container *ngIf="displayHelpLink(i)">
                                <div class="float-end" style="margin-top: -7px !important;">
                                    <button type="button" [id]="'faqButton'+i" class="btn btn-secondary btn-sm"
                                        data-bs-toggle="modal" [attr.data-bs-target]="'#faqModal'+i">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-question-square" viewBox="0 0 16 16">
                                            <path
                                                d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                            <path
                                                d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
                                        </svg> Help
                                    </button>
                                </div>
                            </ng-container>
                        </h1>

                    </div>
                    <p class="card-text" *ngFor='let l of q.LanguageList' [innerHTML]="l.Language"></p>

                    <ng-container *ngIf="q.IsSummary">
                        <ng-container [ngSwitch]="true">
                            <app-summary-item *ngSwitchDefault [questionBlock]="q"
                                [form]="form.get(q.QuestionBlockTypeCode)">
                            </app-summary-item>
                            <app-summary-item-driver-summary></app-summary-item-driver-summary>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="q.CollectorList?.length > 0">
                        <ng-container *ngFor="let collector of sort(q.CollectorList)">
                            <app-question-form-collector [collector]="collector"
                                [form]="form.get(q.QuestionBlockTypeCode)" class="mb-1">
                            </app-question-form-collector>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </form>
    </div>

</ng-template>

<ng-template #genericBlockStyle let-qbs="questionBlocks">
    <ng-container *ngFor='let q of sort(qbs); let i=index;'>
        <ng-container *ngIf='!q.IsSummary || (q.IsSummary && q.SummaryItemList.length>0) || q.CollectorList.length>0'>
            <ng-container *ngTemplateOutlet="singleBlockStyle; context:{index:i, questionBlock:q}">
            </ng-container>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #accordianBlockStyle let-qbs="questionBlocks">
    <div class="accordion" [id]="'accordion' + i">
        <div class="accordion-item">
            <h2 class="accordion-header" id="heading" style="background-color: dark;">
                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                    [attr.data-bs-target]="'#' + q.QuestionBlockTypeCode" aria-expanded="true"
                    [attr.aria-controls]="q.QuestionBlockTypeCode">
                    {{q.Title}} </button>
            </h2>
            <div [id]="q.QuestionBlockTypeCode" class="accordion-collapse collapse show" 
                [attr.data-bs-parent]="'#accordion' + i">
                <div class="accordion-body">

                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #tabBlockStyle let-qbs="questionBlocks">

    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <ng-container *ngFor='let q of sort(qbs); let i=index;'>
            <li class="nav-item" role="presentation">
                <button class="nav-link" data-bs-toggle="tab"  [id]="q.QuestionBlockTypeCode+'Tab'" 
                    [attr.data-bs-target]="'#'+q.QuestionBlockTypeCode"
                    type="button" role="tab" [attr.aria-controls]="q.QuestionBlockTypeCode" aria-selected="false"
                    [ngClass]="{active: q.QuestionBlockTypeCode === activeTab}"
                    [attr.aria-describedby]="q.QuestionBlockTypeCode+'Tabpanel'"
                    (click)="trackActiveTab(q.QuestionBlockTypeCode)">

                    <ng-container class="collector col-md-6" [formGroup]="form" [ngSwitch]="true" *ngIf="form">
                        <svg class="va-icon" *ngSwitchCase="q.QuestionBlockTypeCode === 'GreetingWebIntroBlock'">
                            <use xlink:href="#va-icon-info"></use>
                        </svg>
                        <svg class="va-icon"
                            *ngSwitchCase="q.QuestionBlockTypeCode === 'GreetingWebIntroVehicleSummary'">
                            <use xlink:href="#va-icon-directions_car"></use>
                        </svg>
                        <svg class="va-icon"
                            *ngSwitchCase="q.QuestionBlockTypeCode === 'GreetingWebIntroDriverSummary'">
                            <use xlink:href="#va-icon-airline_seat_recline_normal"></use>
                        </svg>
                        <svg class="va-icon" *ngSwitchCase="q.QuestionBlockTypeCode === 'GreetingWebIntroAuth'">
                            <use xlink:href="#va-icon-warning"></use>
                        </svg>

                    </ng-container>

                </button>
            </li>
        </ng-container>
    </ul>

    <!-- Tab panes -->
    <div class="tab-content">
        <ng-container *ngFor='let q of sort(qbs); let i=index;'>
            <div class="tab-pane" [id]="q.QuestionBlockTypeCode" role="tabpanel"               
                [ngClass]="{active: q.QuestionBlockTypeCode === activeTab}">
                <div class="">
                    <ng-container *ngTemplateOutlet="singleBlockStyle; context:{index:i, questionBlock:q}">
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>
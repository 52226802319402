import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FeedbackRequest, FeedbackService } from '@shared/feedback.service';
import { StorageKey, StorageService } from '@shared/services/storage.service';
import { Observable } from 'rxjs';
import { RecaptchaErrorParameters, RecaptchaFormsModule } from "ng-recaptcha";
import { Config } from '../../app/config';

declare var $: any;


@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  isValidFormSubmitted = false;
  email1: string;
  form: FormGroup;
  isLoginFailed: boolean = true;
  errorMessage: string;
  isResetCaptcha: boolean = false;
  captchaResponse: string;
  popupCloseClicked: boolean = false;
  @ViewChild("closeId") modalButton: ElementRef;
  
  constructor(private feedbackService: FeedbackService, private storage: StorageService, private router: Router) {

  }

  ngOnInit(): void {   
    this.form = new FormGroup({
      message: new FormControl('',[Validators.required,Validators.maxLength(250)]),
      email: new FormControl('', Validators.compose(
        [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"),]))
      //recaptchaReactive: new FormControl(null, Validators.required),
    });
    
  }

  // public resolved(captchaResponse: string): void {
  //   this.captchaResponse = captchaResponse;
  //   console.log(`Resolved captcha with response: ${captchaResponse}`);
  // }



  // public onError(errorDetails: RecaptchaErrorParameters): void {
  //   console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  // }

  public resetControls(form) {
    grecaptcha.reset();
    form.controls["email"].value = "";
    form.controls["message"].value = "";    
  }
  onSubmit($event, form) {
    this.errorMessage = undefined;
    if (form.valid) {
      var carrierId: string = this.storage.getItem(StorageKey.carrierId);
      this.isValidFormSubmitted = true;
      //console.info(this.email);
      //console.info(this.message);
      //console.info(this.router.url);
      this.feedbackService.postPage(this.email.value, this.message.value, this.router.url).subscribe(
        res => {          
          this.errorMessage = "Feedback submitted successfully !";
          //this.isResetCaptcha = true;
          //----------------------------------- Alert Message ------------------------------------//
          this.showModal();
          //form.reset();
          //form.controls.recaptchaReactive.reset();
          //this.recaptcha.markAsTouched({ onlySelf: false });
        },
        err => { 
          this.errorMessage = "Error occured";
          //----------------------------------- Alert Message ------------------------------------//
          this.showModal();     
          //console.info(err) 
        },
        () => {

        }
      );
      return;
    }
    else {
      this.message.markAsTouched({ onlySelf: true });
      this.email.markAsTouched({ onlySelf: true });
     //this.recaptcha.markAsTouched({ onlySelf: true });
    }
  }

  get googleCaptchaKey() { return Config.app.googleCaptchaKey; }
  get callCenterRepTitle() { return this.storage.getItem(StorageKey.callCenterRepTitle); }
  get callCenterPhone() { return this.storage.getItem(StorageKey.callCenterPhone); }
  get callCenterHours() { return this.storage.getItem(StorageKey.callCenterHours); }
  get message() { return this.form.get('message'); }
  get email() { return this.form.get('email'); }
  //get recaptcha() { return this.form.get('recaptchaReactive'); }

  showModal() {            
    let popupErrorElement:HTMLElement = document.getElementById('autoTriggerFeedback') as HTMLElement;
    popupErrorElement.click();
    this.modalButton.nativeElement.focus();
  }
}

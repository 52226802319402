import { Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { QuestionBlockList } from '@shared/models/page-node-model';

export class SummaryItemBase {
    constructor() {
    }

    sort(array): any {
        return array.sort((a, b) => a.SortOrder - b.SortOrder);
    }
}
import { Component, Input, OnInit, EventEmitter, Output, ChangeDetectorRef, SimpleChanges, ViewChildren, QueryList,HostListener, ViewChild, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';
import { AbstractControl, Form, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { PageNodeModel, CollectorList, OptionList, QuestionBlockList, ValidationRuleList } from '@shared/models/page-node-model';
import { formatDate, formatNumber, DatePipe } from '@angular/common';
import { JqueryConversion } from './jquery-conversion';
import { StorageKey, StorageService } from '@shared/services/storage.service';
import { filter, last, map } from 'rxjs/operators';
import { findPhoneNumbersInText } from 'libphonenumber-js/min';
import { from } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';


@Component({
  selector: 'app-question-form',
  templateUrl: './question-form.component.html',
  styleUrls: ['./question-form.component.scss'],
  providers: []
})
export class QuestionFormComponent implements OnInit {
  @Input() page: PageNodeModel;
  @Output() formEvent = new EventEmitter<any>();
  form: FormGroup;
  collectorTypeDict: {
    actionButtons: CollectorList[],
    tels: CollectorList[],
    dates: CollectorList[],
    radioButtons: CollectorList[],
  };
  submitter: string;
  @ViewChildren("cardTitle") target: QueryList<ElementRef>;

  payLoad = '';
  carrierId: number;
  activeTab: string = "GreetingWebIntroAuth";
  displayDivClass: string = 'btn btn-primary';
  // Accessing multiple native DOM elements using QueryList
  buttonCssArray: boolean[] = [];

  displayFaq(index: number, flag: boolean) {
    this.buttonCssArray[index] = flag;
  }

  displayHelpLink(index: number) {

    if(this.carrierId == 104 || this.carrierId == 105)
      return false;
    else{
    if (this.buttonCssArray.length <= 0)
      return true;
    else
      return this.buttonCssArray[index];
    }
  }
  //TODO :ISOQPCMP-15021 -USAA Accessibility UI 2 - 10 Keyboard user should be able to arrow across the tabs.
  @HostListener('window:keydown', ['$event'])
  moveCell(event: any) {
    const element = this.getNextInput(
      event.srcElement,
      event.code
    );
    if (!element) {
      return;
    }
    element.focus();
  }

  getNextInput(currentInput: Element, code: any): HTMLElement {
    if (!currentInput) {
      return null;
    }
    const parentListItem = currentInput.parentElement;
    if(code == "ArrowRight") {

       return parentListItem.nextElementSibling?.firstChild as HTMLElement;
    }
    if(code == "ArrowLeft") {
        
        return parentListItem.previousElementSibling?.firstChild as HTMLElement;
    }
   
  }
  //-------------------------------------------------------------------------------------------------------
  constructor(public storage: StorageService){
  }

  ngOnInit() {
    //this.form = this.toFormGroup(this.page.QuestionBlockList);
    this.carrierId = Number(this.storage.getItem(StorageKey.carrierId));   
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.page) {
      this.form = this.toFormGroup(this.page.QuestionBlockList);
    }
  }


  populate() {
    this.form.patchValue(this.form);
  }


  update() {
    //ajax update call 

  }

  getSubmitter(form: FormGroup) {
    let submitter = this.submitter;
    if (!submitter) {
      let actionButtons = this.collectorTypeDict.actionButtons;
      let c = actionButtons.find(collector => {
        return collector.DefaultValue === 'click';
      });
      submitter = c?.HtmlName;
      c.DefaultValue = '';
    }

    this.submitter = undefined;
    return submitter;
  }

  onSubmit($event, form: FormGroup, collectors) {
    this.payLoad = JSON.stringify(form.getRawValue());
    //TODO : ISUQPCB-11406 - Previous selection of the driver radio button is not showing as selected once user goes back to the page
    this.storage.setItem(StorageKey.payLoadJson, this.payLoad); //browser back & refersh page functionality.
    //-------------------------------------------------------------------------
    //hack WR MVC ButtonCancelTree server side logic and action button hard logic
    let submitter = /*$event.submitter ? $event.submitter?.id : */ this.getSubmitter(form);
    let cancelButton = submitter === 'ButtonCancelTree' ? {} : this.collectorTypeDict.actionButtons.find(collector => collector.HtmlName === submitter && (collector.CollectorTypeCd === "ButtonCancelTree" || collector.CollectorTypeCd === 'CommuteVehicleNotUsed' || collector.CollectorTypeCd === 'ButtonNoBusinessUse'));

    if (!cancelButton && !form.valid) {
      this.validateAllFormFields(form);
      this.activeTab = "GreetingWebIntroAuth";
      return;
    }

    //hack WR MVC: maintain user info collected at welcome page after valid submission
    if (this.page.TypeCode === 'GreetingWebIntro') {
      //button is triggered outside of question block. search collectors through each question block.
      var greetingWebIntroAuthBlock = this.page.QuestionBlockList.find(q => q.QuestionBlockTypeCode === 'GreetingWebIntroAuth');

      let firstNameCollector = greetingWebIntroAuthBlock.CollectorList.find(c => c.CollectorTypeCd === 'GreetingWebNameFirst');
      let lastNameCollector = greetingWebIntroAuthBlock.CollectorList.find(c => c.CollectorTypeCd === 'GreetingWebNameLast');
      const firstName = form.controls['GreetingWebIntroAuth'].get(firstNameCollector.HtmlName).value;
      const lastName = form.controls['GreetingWebIntroAuth'].get(lastNameCollector.HtmlName).value;

      let personSpokenTo = {
        lastName: lastName,
        firstName: firstName,
      };

      this.storage.setItem(StorageKey.personSpokenTo, JSON.stringify(personSpokenTo));
    }


    let actionButtons = this.collectorTypeDict.actionButtons;
    actionButtons.forEach(collector => {
      //TODO: hacked solution. Add triggered ActionButton into FormGroup to be compatible with CCModel FormCollection
      if (collector.HtmlName === submitter) {
        if (JqueryConversion.triggerActionButtonLocal(collector, form, collectors)) {
          $event.preventDefault();
        }
      }
    });

    this.formEvent.emit({ form, submitter, collectorTypeDict: this.collectorTypeDict });
    // if(this.target.last){
    //   this.target.last.nativeElement.focus();
    // }
    //TODO: See the top of the page once lands on the new page.
    window.scroll(0, 0);
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  toFormGroup(questionBlockList: QuestionBlockList[]) {
    this.collectorTypeDict = {
      actionButtons: [],
      tels: [],
      dates: [],
      radioButtons: [],
    }
    const fg = new FormGroup({});

    questionBlockList?.forEach(qb => {
      let childGroup = new FormGroup({});
      fg.addControl(qb.QuestionBlockTypeCode, childGroup);      
      //TODO : ISUQPCB-11406 - Previous selection of the driver radio button is not showing as selected once user goes back to the page
      this.payLoad = this.payLoad === "" ? this.storage.getItem(StorageKey.payLoadJson) : this.payLoad;
      let payLoadJson = this.payLoad !== null ? JSON.parse(this.payLoad)[qb.QuestionBlockTypeCode] : undefined;
      //------------------------------------------------------------------------
      qb.CollectorList?.forEach(collector => {
        let formControl = this.toFormControl(collector);
        if (formControl) {
          childGroup.addControl(collector.HtmlName, formControl);
          //TODO : ISUQPCB-11406 - Previous selection of the driver radio button is not showing as selected once user goes back to the page
          if (payLoadJson !== undefined)
            formControl.setValue(payLoadJson[collector.HtmlName]);
          //------------------------------------------------------------------------
        }
      });

      let summaryGroup = new FormGroup({});
      childGroup.addControl('SummaryItemList', summaryGroup)
      qb.SummaryItemList?.forEach(item => {
        item.CollectorModelList.forEach(collector => {
          let formControl = this.toFormControl(collector);
          if (formControl) { 
            summaryGroup.addControl(collector.HtmlName, formControl);
            //TODO : ISUQPCB-11406 - Previous selection of the driver radio button is not showing as selected once user goes back to the page
            if (payLoadJson !== undefined && payLoadJson["SummaryItemList"] !== undefined ) {
            formControl.setValue(payLoadJson["SummaryItemList"][collector.HtmlName]);
            }
            //------------------------------------------------------------------------           
           }
        }); 
      });

      this.interCollectorEvent(qb, childGroup)
    });

    //return new FormGroup(group);
    return fg;
  }

  interCollectorEvent(qb, form) {
    qb.CollectorList?.forEach(collector => {
      switch (true) {
        case collector.JQueryCode.includes('calculateTotal'):
          let summaryForm = form.get('SummaryItemList');
          let summaryItems = qb.SummaryItemList;
          summaryForm.valueChanges.subscribe(controls => {
            let total = 0;
            summaryItems.forEach(item => {
              item.CollectorModelList.forEach(c => {
                total += +summaryForm.controls[c.HtmlName].value;
              });
            });
            //Note: value property didn't trigger change event
            //form.controls[collector.HtmlName].value = total;
            form.get(collector.HtmlName).setValue(total);
          });
          break;
        case collector.JQueryCode.includes('checkRadioValues'):
          form.get(collector.HtmlName).valueChanges.subscribe(controls => {
            let collectors = qb.CollectorList.filter(c => c.JQueryCode.includes('checkRadioValues'));
            let IsDriverPotentialAgreementMendotary = collectors.some(c => form.get(c.HtmlName)?.value === 'Yes');
            let agreementCollector = qb.CollectorList.find(c => c.CollectorTypeCd === "DriverPotentialAgreement");
            let agreementContol = form.get(agreementCollector.HtmlName);
            agreementContol.setValue(IsDriverPotentialAgreementMendotary, { emitEvent: false });
            if (IsDriverPotentialAgreementMendotary)
              agreementContol.disable({ emitEvent: false });
            else
              agreementContol.enable({ emitEvent: false });
          });
          break;
        case collector.JQueryCode.includes('.click(function(e) { e.preventDefault(); mobilePhoneSetToPrimary(); });'): //PhoneMobileSetUsePrimary
        //Checked: GFB uses it
        //bind click at element or capture at submit
        case collector.JQueryCode.includes('checkRadioOccupationValues'): //CheckRadioDriverOccupation
        case collector.JQueryCode.includes('checkRadioNoOccupationValues'): //CheckRadioNoOccupationValues
          /*Illogical case radio validation GFB occupation page to check if either driver occupation or driver no occupation has values.*/
          //get "RadioDriverOccupation" and "RadioDriverNoOccupation" formcontrol
          var RadioDriverOccupation = qb.CollectorList.filter(c => c.JQueryCode.includes('RadioDriverOccupation'));
          var RadioDriverNoOccupation = qb.CollectorList.filter(c => c.JQueryCode.includes('RadioDriverNoOccupation'));
          //add a custom validator that 
          //  if "RadioDriverOccupation"
          //  both "RadioDriverOccupation" and "RadioDriverNoOccupation" should have checked value
          // if "RadioDriverNoOccupation"
          //  "RadioDriverNoOccupation" should have checked value

          break;
        default:
          break;
      }
    });
  }


  toFormControl(collector: CollectorList): FormControl {

    const validators: any = [];
    collector.ValidationRuleList.forEach(validator => {
      //TODO: temp solution to hack date type from current data schema
      if (validator.TypeCode === 'Date')
        collector.FormInputTypeCode = 'date';
      else if (validator.TypeCode === 'NumericTen') {
        collector.FormInputTypeCode = 'tel';
        validator.Regex = '^\\(?([0-9]{3})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4}).*';
        validators.push(Validators.pattern(validator.Regex));
        validators.push(Validators.required);
      }
      else
        validators.push(Validators.pattern(validator.Regex));
    });

    //transform old jquerycode field in a collector
    const today = formatDate(Date.now(), 'yyyy-MM-dd', 'en');
    switch (true) {
      case collector.JQueryCode.includes('checkPercentUse'): //CheckPercentUse
        //TODO: add "100" validation rule to "total" field in database
        break;
      case collector.JQueryCode.includes('checkRadioOccupationValues'): //CheckRadioDriverOccupation
      case collector.JQueryCode.includes('checkRadioNoOccupationValues'): //CheckRadioNoOccupationValues
        //Checked: Hartford and GFB have these special cases.
        throw 'find a real case usingCheckRadioNoOccupationValues jquery';
        collector.IsRequired = true;
        collector.ValidationRuleList.push({
          Message: 'Please enter an alpha numeric value with atleast one alphabet',
          Regex: '^\\d*[a-zA-Z]+',
          TypeCode: 'CheckRadioDriverOccupation'
        })
        break;
      // case collector.JQueryCode.includes('checkRadioValues'): //CheckRadioPotentialDriver
      //   @interCollectorEvent()
      // break;
      // case collector.JQueryCode.includes('validateConsentFormInput'): //ConsentToRateFormAgree
      //   //Checked: no carrier use it in db
      //   throw 'find a real case usingConsentToRateFormAgree jquery';
      //   break;
      case collector.JQueryCode.includes('.datepicker({ minDate: 0, changeMonth: true, changeYear: true, dateFormat: "mm/dd/yy" });'): //DateMonthDayYear
        collector.FormInputTypeCode = 'date';
        break;
      case collector.JQueryCode.includes('.datepicker({ minDate: 0, changeMonth: true, changeYear: true, dateFormat: "mm/dd/yy" });'): //DateMonthDayYearFuture
        collector.FormInputTypeCode = 'date';
        //min or max attr doesn't work for Date type
        collector.OptionalHtmlAttributes = {
          min: today
        };
        validators.push(CustomValidators.dateMinimum(formatDate(Date.now(), 'yyyy-MM-dd', 'en')));
        break;
      case collector.JQueryCode.includes('.datepicker({ maxDate: 0, minDate: "-100Y", changeMonth: true, changeYear: true, dateFormat: "mm/dd/yy", yearRange: "-100:+0" });'): //DateMonthDayYearPast
        collector.FormInputTypeCode = 'date';
        collector.OptionalHtmlAttributes = {
          max: today
        };
        validators.push(CustomValidators.dateMaximum(formatDate(Date.now(), 'yyyy-MM-dd', 'en')));
        break;
      case collector.JQueryCode.includes('.click(function(event) { getSelectedAddress(event)});'): //GetSelectedAddress
      case collector.JQueryCode.includes('.ready(function()  {occupationcategoryPopUp()});'): //OccupationcategoryPopUp
        //Checked: no carrier use it in db
        throw 'find no implementation in MVC version';
        break;
      // case collector.JQueryCode.includes('.ready(function()  { calculateTotal() });'): //PercentUseTotal
      //   @interCollectorEvent(qb, form) {
      //   break;
      case collector.JQueryCode.includes('.PhoneAlternateCollector'): //PhoneAlternateClear
      case collector.JQueryCode.includes('.PhoneMobileCollector'): //PhoneMobileClear
      case collector.JQueryCode.includes('.PhonePrimaryNumber'): //PhonePrimaryClear
        //add clear button feature on all textbox to replace ActionButton
        return;
      // case collector.JQueryCode.includes('.ready(function() { phoneDialedCheck() });'): //PhonePrimaryShowUseCurrentButton
      //   //for phonescript? 
      //   break;
      case collector.JQueryCode.includes('.ready(function()  { selectInput() });'): //SelectInput
        //Checked: no carrier use it in db
        throw 'find a real case usingSelectInput jquery';
        break;
      case collector.JQueryCode.includes('.click(function() { checkUncheckRadioOccupation("RadioDriverNoOccupation")});'): //UncheckRadioNoOccupation
      case collector.JQueryCode.includes('.click(function() { checkUncheckRadioOccupation("RadioDriverOccupation")});'): //UncheckRadioOccupation
        //Checked: GFB uses it
        throw 'find a real case usingUncheckRadioOccupation/UncheckRadioNoOccupation jquery';
        break;
      // case collector.JQueryCode.includes('.click(function(event) { validateCommuteDescription(event)});'): //ValidateCommuteDescription
      //   // find no implementation in MVC version
      //   break;
      case collector.CollectorTypeCd === 'DriverOccupation': //collector.JQueryCode.includes('.click(function(event) { validateOtherOccupation(event)});'): //ValidateOtherOccupation
        collector.ValidationRuleList.push({
          Message: 'Please enter an alpha numeric value with at least one alphabet',
          Regex: '^\\d*[a-zA-Z]+',
          TypeCode: 'CheckRadioDriverOccupation'
        })
        break;
      case collector.JQueryCode.includes('.change(function() {validateVehicleYear()} );'): //
        //Checked: no carrier use it in db
        throw 'find a real case usingValidateVehicleYear jquery';
        const currentYear = new Date().getFullYear();
        collector.OptionalHtmlAttributes = {
          min: 1950,
          max: currentYear + 1
        };
        break;
      default:
        break;
    };

    // add validator rules there
    // require
    if (collector.IsRequired) {
      collector.FormInputTypeCode === "CheckBox" ? validators.push(Validators.requiredTrue) : validators.push(Validators.required);
    }

    //maxlength with hack
    if (collector.FormInputTypeCode !== 'tel') {
      if (collector.MaxCharacters > 0) { validators.push(Validators.maxLength(collector.MaxCharacters)); }
      else {
        const anotherMaxLengthPlace = +this.getAttributeValue(collector.OptionalHtmlAttributes, 'maxlength');
        if (anotherMaxLengthPlace > 0) { validators.push(Validators.maxLength(anotherMaxLengthPlace)); }
      }
    }

    //parse initial value
    let defaultVal = collector.DefaultValue;
    switch (collector.FormInputTypeCode) {
      case 'Radio':
      case 'RadioInline':
        this.collectorTypeDict.radioButtons.push(collector);
        //if (defaultVal?.length <= 0) defaultVal = this.getSelectedOption(collector.OptionList);
        //TODO: hack default value set as a non-existing option value from backend (or db), e.g. '~'. Database should store dropdown value as null if there is no selected value at all. 
        defaultVal = collector.OptionList.find(o => o.Value === defaultVal)?.Value;
        break;
      case 'TextBox':
        collector.FormInputTypeCode = this.getInputType(collector);
        break;
      case 'ActionButton':
        this.collectorTypeDict.actionButtons.push(collector);
        break;
      case 'tel':
        this.collectorTypeDict.tels.push(collector);
        break;
      case 'date':
        try {
          defaultVal = formatDate(collector.DefaultValue, 'yyyy-MM-dd', 'en');
        } catch (error) {

        }
        this.collectorTypeDict.dates.push(collector);
        break;
      case 'DropDown':
        //TODO: hack default value set as a non-existing option value from backend (or db), e.g. '~'. Database should store dropdown value as null if there is no selected value at all. 
        defaultVal = collector.OptionList.find(o => o.Value === defaultVal)?.Value;
        break;
    }
    const control = new FormControl(defaultVal || '');
    control.setValidators(validators);

    return control;
  }


  sort(array): any {
    return array.sort((a, b) => a.SortOrder - b.SortOrder);
  }

  getSelectedOption(options) {
    return options.filter(o => o.IsSelected)[0]?.Value || '';
  }

  setSelectedOption(options, val) {
  }

  getAttributeValue(OptionalHtmlAttributes: string, attributeName: string): string {
    //const regex = /maxlength\s*=\s*"(\d+)"/g;
    const regex = new RegExp(attributeName + '\\s*=\\s*"(\\d+)"', 'g');
    let rslt = regex.exec(OptionalHtmlAttributes);
    if (rslt) return rslt[1];
    else return '' //'/\d+((.|,)\d+)?/'
  }

  getInputType(collector: CollectorList): string {
    let rslt = new RegExp('maxlength=\"3\"').test(collector.OptionalHtmlAttributes);
    if (rslt) return 'number'

    return collector.FormInputTypeCode;
  }

  private dateRangeValidator: ValidatorFn = (): {
    [key: string]: any;
  } | null => {
    /*
    let invalid = false;
    const from = this.fg && this.fg.get("from").value;
    const to = this.fg && this.fg.get("to").value;
    if (from && to) {
      invalid = new Date(from).valueOf() > new Date(to).valueOf();
    }
    return invalid ? { invalidRange: { from, to } } : null;
    */
    return null;
  };

  trackActiveTab(blockTypeCode: string) {
    this.activeTab = blockTypeCode;
  }

}


export class CustomValidators {
  static dateMinimum(date: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const validationDate = new Date(date);
      const controlDate = new Date(control.value);

      return controlDate >= validationDate ? null : {
        'dateMinimum': {
          'required': date,
          'actual': control.value
        }
      };
    };
  }

  static dateMaximum(date: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const validationDate = new Date(date);
      const controlDate = new Date(control.value);

      return controlDate <= validationDate ? null : {
        'dateMaximum': {
          'required': date,
          'actual': control.value
        }
      };
    };
  }

  static checkRadioDriverOccupation(radioDriverOccupation: FormControl, radioDriverNoOccupation: FormControl): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (radioDriverOccupation && radioDriverNoOccupation) {
        return radioDriverOccupation.valid && radioDriverNoOccupation.valid ? null : {
          'checkRadioDriverOccupation': {
            'required': '',
            'actual': control.value
          }
        };
      }

      if (radioDriverNoOccupation) {
        return radioDriverNoOccupation.valid ? null : {
          'checkRadioDriverOccupation': {
            'required': '',
            'actual': control.value
          }
        };
      }
    };
  }




  /*
  static fromToDate(fromDateField: string, toDateField: string, errorName: string = 'fromToDate'): ValidatorFn {
    return (formGroup: AbstractControl): { [key: string]: boolean } | null => {
      const fromDate = formGroup.get(fromDateField).value;
      const toDate = formGroup.get(toDateField).value;
      // Ausing the fromDate and toDate are numbers. In not convert them first after null check
      if ((fromDate !== null && toDate !== null) && fromDate > toDate) {
        return { [errorName]: true };
      }
      return null;
    };
  }
  */
}

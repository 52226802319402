import { Component, OnInit } from '@angular/core';
import { StorageKey, StorageService } from '@shared/services/storage.service';

@Component({
  selector: 'app-common-faq',
  templateUrl: './common-faq.component.html',
  styleUrls: ['./common-faq.component.scss']
})
export class CommonFaqComponent implements OnInit {

  carrierId : number;

  constructor(public storage: StorageService) { }

  ngOnInit(): void {
    this.carrierId = Number( this.storage.getItem(StorageKey.carrierId));
  }

}

import {
    ContentChildren,
    Directive,
    HostListener,
    QueryList,ElementRef
  } from '@angular/core';
  import { NgControl } from '@angular/forms';
  
  @Directive({
    selector: '[focusInvalidInput]',
  })
  export class FormDirective {
    constructor(private element: ElementRef) { }
    @ContentChildren(NgControl) formControls: QueryList<NgControl>; 
    @HostListener('submit')
    onFormSubmit() {      
        const formelement = this.element.nativeElement;
        const invalidControl = formelement.querySelector('.ng-invalid[id*="qpCol"]') == null ? formelement.querySelector('.ng-invalid') 
                    : formelement.querySelector('.ng-invalid[id*="qpCol"]');            
        if (invalidControl) {
          invalidControl?.focus();  
        }       
      }
  }
<!-- <app-navbar></app-navbar> -->
<!-- <section class="section">
    <div class="container"> -->
<!-- <header class="headline text center">
        <h4 class="title ">FAQ</h4>
    </header> -->
<div class="accordion" [id]="'accordion'+type1">
  <div class="accordion-item" *ngFor="let hero of response?.QuestionBlockHelpFAQList; let i=index; " >
    <h2 class="accordion-header" [id]="'heading'+hero.HelpFAQ.helpFAQId+type1">
      <button style="text-align: left; padding-top: 5px; padding-bottom: 5px;" class="accordion-button collapsed"
        type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse'+hero.HelpFAQ.helpFAQId+type1"
        aria-expanded="false" [attr.aria-controls]="'collapse'+hero.HelpFAQ.helpFAQId+type1" >
        <b>{{i+1}}</b>&nbsp;  {{hero.HelpFAQ.question}}
      </button>
    </h2>
    <div [id]="'collapse'+hero.HelpFAQ.helpFAQId+type1" class="accordion-collapse collapse"
     [attr.data-bs-parent]="'#accordion'+type1">
      <div tabindex="0"  class="accordion-body" style="padding-top: 5px; padding-bottom: 5px;">
        {{hero.HelpFAQ.answer}}
      </div>
    </div>
  </div>
</div>
<!-- </div>
</section> -->
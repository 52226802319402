<ng-container class="collector col-md-6" [formGroup]="form" [ngSwitch]="true" *ngIf="form">

    <!-- <pre *ngSwitchDefault>unknow input type</pre> -->

    <ul class="" [class]="collector.LabelCssClass" *ngSwitchCase="collector.FormInputTypeCode === 'BulletList'">
        <li class="" *ngFor='let option of collector.OptionList; let i = index'>
            {{option.Description}}</li>
    </ul>

    <img [src]="collector.DefaultValue" class="img-fluid" [class]="collector.LabelCssClass" alt=""
        *ngSwitchCase="collector.FormInputTypeCode === 'Image'">

    <div attr.aria-label= "Select {{collector.Label}}"  class="form-group" *ngSwitchCase="collector.FormInputTypeCode === 'Radio'" [class]="collector.LabelCssClass">
        <div class="mr-3">
            <label *ngIf="collector.Label?.length > 0" [class]="{required: collector.IsRequired}"
                [attr.for]="collector.HtmlName" [innerHTML]="collector.Label">
            </label>
        </div>
        <ng-container *ngFor='let option of sort(collector.OptionList); let i = index'>
            <div class="form-check mr-2">
                <input type="radio" class="form-check-input" [formControlName]="collector.HtmlName"
                    [id]="collector.HtmlName+i" [name]="collector.HtmlName" [value]="option.Value"
                    [checked]="option.IsSelected">
                <label class="form-check-label word-wrapping"
                    [attr.for]="collector.HtmlName+i">{{option.Description}}</label>
            </div>

        </ng-container>
        <div role="alert">
            <small class="error-block text-danger" *ngIf="isInvalid">
                <i class="material-icons me-2">warning</i>{{errorMessage}} </small>
        </div>
    </div>

    <div  attr.aria-label= "Select {{collector.Label}}" class="form-group form-inline" *ngSwitchCase="collector.FormInputTypeCode === 'RadioInline'"
        [class]="collector.LabelCssClass">
        <!-- <span class="me-4 fw-normal" style="display: inline-block;"> -->
        <label *ngIf="collector.Label?.length > 0" class="me-4 fw-normal" [class]="{required: collector.IsRequired}"
            style="display: inline-block;" [attr.for]="collector.HtmlName" [innerHTML]="collector.Label"></label>
        <!-- </span> -->
        <div style="display: inline-block;">
            <div class="form-check form-check-inline" *ngFor='let option of sort(collector.OptionList); let i = index'>
                <input type="radio" class="form-check-input" [formControlName]="collector.HtmlName"
                    [id]="collector.HtmlName+i" [name]="collector.HtmlName" [value]="option.Value"
                    [checked]="option.IsSelected">
                <label class="form-check-label word-wrapping"
                    [attr.for]="collector.HtmlName+i">{{option.Description}}</label>
            </div>
        </div>
        <div role="alert">
            <small class="error-block text-danger" *ngIf="isInvalid"><i
                    class="material-icons me-2">warning</i>{{errorMessage}}
            </small>
        </div>
    </div>


    <div class="form-group" *ngSwitchCase="collector.FormInputTypeCode === 'Hidden'" [class]="collector.LabelCssClass">
        <input class="form-control" [formControlName]="collector.HtmlName" [id]="collector.HtmlName"
            [type]="collector.FormInputTypeCode">
    </div>


    <a href="#" class="btn btn-primary" [class]="collector.LabelCssClass"
        *ngSwitchCase="collector.FormInputTypeCode === 'Link'">{{collector.Label}}</a>

    <div class="form-group mt-3 "
        *ngSwitchCase="collector.FormInputTypeCode === 'ActionButton' && form.controls[collector.HtmlName] != null"
        [class]="collector.LabelCssClass">
        <button  class="btn btn-primary me-2 btn-sm " type="submit" [attr.aria-label] = "'button ' + collector.Label"
            [id]="collector.HtmlName" [name]="collector.HtmlName" (click)="onButtonClick('click')"
            [value]="collector.CollectorTypeCd">{{collector.Label}}</button>
    </div>


    <div attr.aria-label= "enter {{collector.Label}}" class="form-group form-floating mb-3" *ngSwitchCase="collector.FormInputTypeCode === 'TextArea'"
        [class]="collector.LabelCssClass">
        <textarea class="form-control" [formControlName]="collector.HtmlName" [id]="collector.HtmlName"
            style="height: 100px"></textarea>
        <label [class]="{required: collector.IsRequired}" [attr.for]="collector.HtmlName">{{collector.Label}}</label>
        <div role="alert">
            
            <small class="error-block text-danger" *ngIf="isInvalid"><i
                    class="material-icons me-2">warning</i>{{errorMessage}} </small>
        </div>
    </div>


    <div     class="form-group form-floating mb-3 position-relative"
        *ngSwitchCase="collector.FormInputTypeCode === 'TextBox' || collector.FormInputTypeCode === 'text'"
        [class]="collector.LabelCssClass">
        <input class="form-control" [formControlName]="collector.HtmlName" [id]="collector.HtmlName"   
            [type]="collector.FormInputTypeCode">
        <label [class]="{required: collector.IsRequired} "
        [attr.aria-describedby]="'val_'+collector.HtmlName"
        [attr.for]="collector.HtmlName">{{collector.Label}}</label>
        <span class="visually-hidden" [id]="'val_'+collector.HtmlName">Please enter {{collector.Label}}. {{getErrorMessages(collector.ValidationRuleList)}}</span>

        <div role="alert">
            <small class="error-block text-danger" *ngIf="isInvalid"><i
                    class="material-icons me-2">warning</i>{{errorMessage}} </small>
        </div>
        <!-- <span class="form-clear Hidden" *ngIf="hasValue" (click)="clearValue()"><i class="material-icons">clear</i></span> -->
    </div>

    <div   attr.aria-label= "enter {{collector.Label}}" style="width:200% !important" class="form-group form-floating mb-3 position-relative"
        *ngSwitchCase="collector.FormInputTypeCode === 'number'" [class]="collector.LabelCssClass">
        <input class="form-control" [formControlName]="collector.HtmlName" [id]="collector.HtmlName"
            [type]="collector.FormInputTypeCode" (click)="$event.target.select()">
        <label [class]="{required: collector.IsRequired}" [attr.for]="collector.HtmlName">{{collector.Label}}</label>
        <div role="alert">
            <small class="error-block text-danger" *ngIf="isInvalid"><i
                    class="material-icons me-2">warning</i>{{errorMessage}}
            </small>
        </div>
        <!-- <span class="form-clear Hidden" *ngIf="hasValue" (click)="clearValue()"><i class="material-icons">clear</i></span> -->
    </div>

    <div class="form-group form-floating mb-3" *ngSwitchCase="collector.FormInputTypeCode === 'date'"
        [class]="collector.LabelCssClass">
        <span class="visually-hidden" [id]="'val_'+collector.HtmlName">Please enter {{collector.Label}}. {{getErrorMessages(collector.ValidationRuleList)}}</span>

        <input class="form-control" [formControlName]="collector.HtmlName" [id]="collector.HtmlName"
            [type]="collector.FormInputTypeCode" [min]='collector.OptionalHtmlAttributes?.min'
            [max]='collector.OptionalHtmlAttributes?.max' [attr.aria-label]="condition ? this.dateSelected(): dateParam" 
            (change)="dateTransformChange($event)"
            (keydown) ="dateTransformKeyDown($event)"
            (click) ="dateTransformClick($event)"
            >
            <div aria-live="assertive" 
            *ngIf="condition" > <div  [attr.aria-label]="dateParam" aria-atomic="true" aria-relevant="additions text"></div>
            </div>
        <label [class]="{required: collector.IsRequired}"
        [attr.aria-describedby]="'val_'+collector.HtmlName"
        [attr.for]="collector.HtmlName">{{collector.Label}}</label>
        <div role="alert">
            <small class="error-block text-danger" *ngIf="isInvalid"><i
                    class="material-icons me-2">warning</i>{{errorMessage}} </small>
        </div>
    </div>

    <div  attr.aria-label= "enter {{collector.Label}}" class="form-group form-floating mb-3 position-relative" *ngSwitchCase="collector.FormInputTypeCode === 'tel'"
        [class]="collector.LabelCssClass">
        <!-- <input class="form-control" [formControlName]="collector.HtmlName" [id]="collector.HtmlName" type="tel"
            [value]="form.get(collector.HtmlName).value | phone:'US'"> -->
        <input class="form-control" appPhoneMask [formControlName]="collector.HtmlName" [id]="collector.HtmlName"
            type="tel">
        <label [class]="{required: collector.IsRequired}" [attr.for]="collector.HtmlName">{{collector.Label}}</label>
        <!-- <span class="form-clear Hidden" *ngIf="hasValue" (click)="clearValue()"><i class="material-icons">clear</i></span> -->
        <div role="alert">
            <small class="error-block text-danger" *ngIf="isInvalid"><i
                    class="material-icons me-2">warning</i>{{errorMessage}} </small>
        </div>
        <!-- <input class="form-control" type="text" appPhoneMask [formControlName]="collector.HtmlName" id="phone-number"> -->

    </div>


    <div  class="dropdown form-floating mb-3" *ngSwitchCase="collector.FormInputTypeCode === 'DropDown'"
        [class]="collector.LabelCssClass">
        <span class="visually-hidden" [id]="'val_'+collector.HtmlName">Select {{collector.Label}}</span>

        <!-- <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            -- please select --
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" href="#" *ngFor='let o of collector.OptionList'>{{o.Description}}</a>
        </div> -->
        <select class="form-select" [id]="collector.HtmlName" [formControlName]="collector.HtmlName" >
            <option style="display:none"></option>
            <option *ngFor="let opt of sort(collector.OptionList)" [value]="opt.Value">{{opt.Description}}
            </option>
        </select>
        <label [class]="{required: collector.IsRequired}" [attr.for]="collector.HtmlName"  [attr.aria-describedby]= "'val_'+collector.HtmlName">{{collector.Label}}</label>
        <div role="alert">
            <small class="error-block text-danger" *ngIf="isInvalid"><i
                    class="material-icons me-2">warning</i>{{errorMessage}}
            </small>
        </div>
    </div>

    <div   class="form-group form-check" *ngSwitchCase="collector.FormInputTypeCode === 'CheckBox'"
        [class]="collector.LabelCssClass">
        <!-- <span class="visually-hidden" [id]="'val_'+collector.HtmlName">Select {{collector.Label}}</span> -->

        <ng-container *ngIf="!formControl.disabled">
            <input type="checkbox" class="form-check-input" [formControlName]="collector.HtmlName"
           
                [id]="collector.HtmlName">
            <label class="form-check-label " [class]="{required: collector.IsRequired}" [attr.for]="collector.HtmlName"
                [innerHTML]="collector.Label"></label>
            <div role="alert">
                <small class="error-block text-danger" *ngIf="isInvalid"><i
                        class="material-icons me-2">warning</i>{{errorMessage}}
                </small>
            </div>
        </ng-container>
    </div>
</ng-container>
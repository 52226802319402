import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PageNodeModel, CollectorList, OptionList, QuestionBlockList } from '@shared/models/page-node-model';
import { SummaryItemBase } from './summary-item-base'


@Component({
  selector: 'app-summary-item-business-use',
  templateUrl: './summary-item-business-use.component.html',
  styleUrls: ['./summary-item-business-use.component.scss']
})
export class SummaryItemBusinessUseComponent extends SummaryItemBase implements OnInit {
  @Input() questionBlock: QuestionBlockList;
  @Input() form: FormGroup;

  constructor() { super(); }

  ngOnInit(): void {
  }

}

<div class="container" style="padding:5%">
  <div class="row mb-4">
    <div class="col">
      <img [src]="'assets/logo/' + carrierlogo" style="max-width: 190px;" alt="Logo" />
    </div>
    <div class="col">
      <div><b><small>Authorized By: </small></b> {{personSpokenTo}}</div>
      <div><b><small>Date:</small></b> {{datePrint}}</div>
      <div><b><small>{{policyNumberLabel}} </small></b> {{policyNumber}}</div>
      <div><b><small>Confirmation code: </small></b>{{confirmationCode}}</div>
    </div>
  </div>

  <ng-container *ngTemplateOutlet="genericBlockStyle; context:{questionBlocks:page?.QuestionBlockList}">
  </ng-container>

</div>

<ng-template #genericBlockStyle let-qbs="questionBlocks">
  <ng-container *ngFor='let q of qbs; let i=index;'>
    <ng-container *ngIf='!q.IsSummary || (q.IsSummary && q.SummaryItemList.length>0)'>
      <div class="row" style="page-break-inside: avoid;">
        <ng-container *ngTemplateOutlet="singleBlockStyle; context:{index:i, questionBlock:q}">
        </ng-container>
      </div>
      <div class="row">
        <div class="col">
          <br />
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #singleBlockStyle let-i="index" let-q="questionBlock">

  <div class="row">
    <div class="col title">
      <h5 class="question-title">{{q.Title}}</h5>
    </div>
  </div>
  <div class="row language">
    <div class="col" *ngFor='let l of q.LanguageList' [innerHTML]="l.Language">
    </div>
  </div>

  <ng-container *ngIf="q.IsSummary">
    <div class="row border">
      <div *ngIf="q.SummaryItemHeaderDict[1]" class="col">
        <strong class="pe-4">{{q.SummaryItemHeaderDict[1]?.LabelText}}</strong>
      </div>
      <div *ngIf="q.SummaryItemHeaderDict[2]" class="col">
        <strong class="pe-4">{{q.SummaryItemHeaderDict[2]?.LabelText}}</strong>
      </div>
      <div *ngIf="q.SummaryItemHeaderDict[3]" class="col">
        <strong class="pe-4">{{q.SummaryItemHeaderDict[3]?.LabelText}}</strong>
      </div>
      <div *ngIf="q.SummaryItemHeaderDict[4]" class="col">
        <strong class="pe-4">{{q.SummaryItemHeaderDict[4]?.LabelText}}</strong>
      </div>
      <div *ngIf="q.SummaryItemHeaderDict[5]" class="col">
        <strong class="pe-4">{{q.SummaryItemHeaderDict[5]?.LabelText}}</strong>
      </div>
      <div *ngIf="q.SummaryItemHeaderDict[6]" class="d-flex justify-content-between">
        <strong class="pe-4">{{q.SummaryItemHeaderDict[6]?.LabelText}}</strong>
      </div>
    </div>

    <div class="row border" *ngFor='let item of q.SummaryItemList'>

      <div class="col" *ngIf="q.SummaryItemHeaderDict[1]">
        <small class="pe-4" [innerHtml]="item?.Column1">}</small>
      </div>

      <div class="col" *ngIf="q.SummaryItemHeaderDict[2]">
        <small class="pe-4" [innerHtml]="item?.Column2"></small>
      </div>

      <div class="col" *ngIf="q.SummaryItemHeaderDict[3]">
        <small class="pe-4" [innerHtml]="item?.Column3">}</small>
      </div>

      <div class="col" *ngIf="q.SummaryItemHeaderDict[4]">
        <small class="pe-4" [innerHtml]="item?.Column4"></small>
      </div>

      <div class="col" *ngIf="q.SummaryItemHeaderDict[5]">
        <small class="pe-4" [innerHtml]="item?.Column5"></small>
      </div>

      <div class="col" *ngIf="q.SummaryItemHeaderDict[6]">
        <small class="pe-4" [innerHtml]="item?.Column6"></small>
      </div>

      <div class="mt-4">
        <ng-container *ngFor="let collector of sort(item.CollectorModelList)" class="form-row">
          <app-question-form-collector [collector]="collector" [form]="form" class="mb-1"
            [class]="{newline: collector.FormInputTypeCode !== 'ActionButton'}">
          </app-question-form-collector>
        </ng-container>
      </div>
      <div class="d-block gap-2">
      </div>
    </div>
  </ng-container>

</ng-template>
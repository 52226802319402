<ng-container *ngIf="(carriers$ | async) as carriers">

    <main class="">
        <div class="fixed-center-container">
            <div class="box">
                <form class="" (ngSubmit)="onSubmit($event, form)" [formGroup]="form">
                    <div class="form-row dropdown form-floating mb-3">
                        <select class="form-select" id="carrier" formControlName="carrierId" required>
                            <option *ngFor="let c of carriers" [value]="c.Id">{{c.Name}}
                            </option>
                        </select>
                        <label class="" for="carrier">Carriers</label>
                    </div>
                    <div class="form-row">
                        <button class="btn btn-primary me-2" type="submit" name="submit" id="submit"
                            [disabled]="form.invalid">Enter Client's WebResponse</button>
                        <button class="btn btn-primary me-2" type="button" name="reset" id="reset"
                            (click)="resetTestPolicies()" [disabled]="form.invalid">Reset Test Policies</button>
                    </div>
                    <span class="error-block" class="text-danger">{{resetPolicyMessage}}</span>

                </form>

            </div>

        </div>
    </main>
</ng-container>
import { Component, OnInit } from '@angular/core';
import { StorageKey, StorageService } from '@shared/services/storage.service';
import { AuthService, PageContent } from '@shared/security/auth.service';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute, Event, Params } from "@angular/router";



@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  logo: string;// Create Member which is used for logo display


  //loginRes: any;
  constructor(private storage: StorageService,private auth: AuthService) { 
   
  }
  

  ngOnInit(): void {    
           
    let res = this.storage.getItem(StorageKey.logoFile);  
    if(!res) //Check for undefined,null,empty value....
    {
      
      let carrierId = this.storage.getItem(StorageKey.carrierId)
      let loginPage = this.auth.getLoginPage(carrierId); 

      loginPage.subscribe(res => {
        if (res?.CarrierId >= 0) {
          this.storage.setItem(StorageKey.carrierId, res.CarrierId?.toString());
          this.storage.setItem(StorageKey.bannerText, res.BannerText?.toString());
          this.storage.setItem(StorageKey.callCenterRepTitle, res.CallCenterRepTitle?.toString());
          this.storage.setItem(StorageKey.callCenterPhone, res.CallCenterPhone?.toString());
          this.storage.setItem(StorageKey.callCenterHours, res.CallCenterHours?.toString());
          this.logo = res.CarrierLogoFile; // assigning the  member
        }
        else {
          throw ({ status: 400, errorMessage: 'no carrier id' });
        }
      });

    }
    else
    {
      this.logo = res;

    }
         
  }

  getCarrierImage() {    
    var imgFile = this.storage.getItem(StorageKey.logoFile);
    return imgFile;
  }
  

}

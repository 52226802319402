<p>topbar works!</p>
<nav class="BarronsTheme--burger-2g95b-Who30IOa3kHzONoO BarronsTheme--full-page-burger-lO2lyQP4rq206iYO6BFSV ">
    <div class="BarronsTheme--menu-2WdwMqgizK1rOHFLcrcyo4 ">
        <div class="BarronsTheme--column-container-2DVr0hLI83VbMdurbkwRxm ">
            <div class="BarronsTheme--column-1-1xIlLjPil5Fr6xEbHIAOrs BarronsTheme--column-2bKD9bBe7NBFdOAgivi9s4 "><a
                    class="BarronsTheme--item-3xA8M3CyjQN_v39I0ZA2zu "
                    href="https://onboarding.barrons.com/interests?v=member&amp;mod=BOL_HAMNAV">Subscriber
                    Benefits</a><a class="BarronsTheme--item-3xA8M3CyjQN_v39I0ZA2zu "
                    href="https://www.barrons.com/newsletters?mod=BOL_HAMNAV">Newsletters</a><a
                    class="BarronsTheme--item-3xA8M3CyjQN_v39I0ZA2zu "
                    href="https://www.barrons.com/podcasts?mod=BOL_HAMNAV">Podcasts</a><a
                    class="BarronsTheme--item-3xA8M3CyjQN_v39I0ZA2zu "
                    href="https://www.barrons.com/video?mod=BOL_HAMNAV">Video</a><a
                    class="BarronsTheme--item-3xA8M3CyjQN_v39I0ZA2zu "
                    href="https://www.barrons.com/topics?mod=BOL_HAMNAV">Topics</a></div>
            <div class="BarronsTheme--column-2bKD9bBe7NBFdOAgivi9s4 "><a
                    class="BarronsTheme--item-3xA8M3CyjQN_v39I0ZA2zu "
                    href="https://www.barrons.com/magazine?mod=BOL_HAMNAV">Magazine</a><a
                    class="BarronsTheme--item-3xA8M3CyjQN_v39I0ZA2zu "
                    href="https://www.barrons.com/market-data?mod=BOL_HAMNAV">Data</a><a
                    class="BarronsTheme--item-3xA8M3CyjQN_v39I0ZA2zu "
                    href="https://www.barrons.com/real-time?mod=BOL_HAMNAV">RealTime Analysis</a><a
                    class="BarronsTheme--item-3xA8M3CyjQN_v39I0ZA2zu "
                    href="https://www.barrons.com/400?mod=BOL_HAMNAV">Barron's 400</a><a
                    class="BarronsTheme--item-3xA8M3CyjQN_v39I0ZA2zu "
                    href="https://www.barrons.com/directory?mod=BOL_HAMNAV">Financial Advisor Directory</a><a
                    class="BarronsTheme--item-3xA8M3CyjQN_v39I0ZA2zu "
                    href="https://www.barrons-conferences.com/index.html?mod=BOL_HAMNAV">Conferences</a></div>
            <div class="BarronsTheme--column-3-lDcwbwllw3d9wU8bDTfHn BarronsTheme--column-2bKD9bBe7NBFdOAgivi9s4 "><a
                    class="BarronsTheme--item-3xA8M3CyjQN_v39I0ZA2zu "
                    href="http://ereader.barrons.com/?mod=BOL_HAMNAV">E-Edition</a><a
                    class="BarronsTheme--item-3xA8M3CyjQN_v39I0ZA2zu "
                    href="https://www.djreprints.com/?mod=BOL_HAMNAV">Reprints</a><a
                    class="BarronsTheme--item-3xA8M3CyjQN_v39I0ZA2zu "
                    href="https://wsjshop.com/collections/barrons?mod=BOL_HAMNAV">Buy Issues</a><a
                    class="BarronsTheme--item-3xA8M3CyjQN_v39I0ZA2zu "
                    href="https://mediakit.wsjbarrons.com?mod=BOL_HAMNAV">Advertising</a><a
                    class="BarronsTheme--item-3xA8M3CyjQN_v39I0ZA2zu "
                    href="https://www.barrons-mediakit.com/college.html?mod=BOL_HAMNAV">College Program</a><a
                    class="BarronsTheme--item-3xA8M3CyjQN_v39I0ZA2zu "
                    href="mailto:press@barrons.com?mod=BOL_HAMNAV">Press &amp; Media Inquiries</a></div>
            <div class="BarronsTheme--column-4-zGVpl7b053VuJ4iqcAIsn BarronsTheme--column-2bKD9bBe7NBFdOAgivi9s4 "><a
                    class="BarronsTheme--item-3xA8M3CyjQN_v39I0ZA2zu "
                    href="https://www.barrons.com/about/masthead?mod=BOL_HAMNAV">Barron's Masthead</a><a
                    class="BarronsTheme--item-3xA8M3CyjQN_v39I0ZA2zu "
                    href="https://www.foxbusiness.com/category/barrons-roundtable?mod=BOL_HAMNAV">Barron's Roundtable
                    TV</a><a class="BarronsTheme--item-3xA8M3CyjQN_v39I0ZA2zu "
                    href="https://www.barrons.com/penta?mod=BOL_HAMNAV">Penta</a><a
                    class="BarronsTheme--item-3xA8M3CyjQN_v39I0ZA2zu "
                    href="https://www.barrons.com/advisorcenter?mod=BOL_HAMNAV">Advisors</a><a
                    class="BarronsTheme--item-3xA8M3CyjQN_v39I0ZA2zu "
                    href="https://www.dowjones.com?mod=BOL_HAMNAV">Dow Jones</a></div>
        </div>
        <div class="BarronsTheme--socialbar-3FAwdauXwZPB-a-SLfeyPn ">
            <div class="BarronsTheme--left-col-1vsc72RSEObsp2GxXdEXYd ">
                <div class="BarronsTheme--title-2fEeYbsAQuHc-cb4qdA-Fr "><span>Find Us</span></div>
                <div class="BarronsTheme--mobile-ttlAk70TtD0sOs17zVoN8 ">
                    <div
                        class="BarronsTheme--social-icon-2V7QYDpM9malgiOVAV7ZES BarronsTheme--ios-1bFsQN7TNiUjXRYkPoVC9v ">
                        <a href="https://itunes.apple.com/us/app/barrons/id409882593?mt=8"></a>
                    </div>
                    <div
                        class="BarronsTheme--social-icon-2V7QYDpM9malgiOVAV7ZES BarronsTheme--android-15XQIrLhjm9VJ4XUGAIl17 ">
                        <a href="https://play.google.com/store/apps/details?id=com.barrons.us"></a>
                    </div>
                </div>
            </div>
            <div class="BarronsTheme--right-col-IQUwczpKRgXxytEYl9bw2 ">
                <div class="BarronsTheme--left-XMJibXVLOljuRwjmu0eRR ">
                    <div
                        class="BarronsTheme--social-icon-2V7QYDpM9malgiOVAV7ZES BarronsTheme--fb-38BIi4HjISaT8uVH2ourin ">
                        <a href="https://www.facebook.com/barrons"></a>
                    </div>
                    <div
                        class="BarronsTheme--social-icon-2V7QYDpM9malgiOVAV7ZES BarronsTheme--twitter-1O1gLMlgX42kqNwTjb7Xk5 ">
                        <a href="https://twitter.com/barronsonline"></a>
                    </div>
                    <div
                        class="BarronsTheme--social-icon-2V7QYDpM9malgiOVAV7ZES BarronsTheme--linkedin-2JYUBq11leLMF-v7M4Sh3D ">
                        <a href="https://www.linkedin.com/company/41970"></a>
                    </div>
                    <div
                        class="BarronsTheme--social-icon-2V7QYDpM9malgiOVAV7ZES BarronsTheme--instagram-2kCTWZxn5-m2HsHUk4iZYc ">
                        <a href="https://www.instagram.com/Barrons/"></a>
                    </div>
                </div>
                <div class="BarronsTheme--right-h5h4imkEuBQyC8XVCTlIb ">
                    <div class="BarronsTheme--applenews-2tGn2WZCikHtIXZfPpgyMw "><a
                            href="https://www.apple.com/news/">Apple News</a></div>
                    <div class="BarronsTheme--flipboard-1ZqfEqKe38xbHfyWwQVyiD "><a
                            href="https://flipboard.com/@Barrons">Flipboard</a></div>
                </div>
            </div>
        </div>
    </div>
</nav>
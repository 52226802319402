<app-navbar></app-navbar>
<main class="min-vh-100">
    <div class="fixed-center-container">
        <div class="container text-center">
            <div class="row">
                <div class="col align-self-center">
                    <img class="" [src]="'assets/logo/'+logo" alt="Logo" width="200px" />
                </div>
            </div>
            <div class="row">
                <div class="col align-self-center">
                    <h1>Let's get you back on track</h1>
                    <p>The page you were navigating to may have been moved or deleted. Please <a class="" href="#"
                            routerLink="/login">go to our homepage and sign in.</a>
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="d-grid gap-2 align-self-center">
                    <a class="btn btn-primary btn-block" href="#" routerLink="/login">Sign in</a>
                </div>
            </div>
            <div class="row">
                <div class="align-items-center mt-4">
                    <img class="center" src="assets/logo/verisk-logo.svg" width="110" />
                </div>
            </div>

        </div>
    </div>
</main>
<app-footer></app-footer>
import { Injectable } from '@angular/core';
import { of, Observable, throwError } from 'rxjs';
import { map, catchError, retry, share, shareReplay, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { Config } from '../app/config';

export interface Response {
  Id: number;
  Name?: string;
}

@Injectable({
  providedIn: 'root'
})
export class CarrierService {

  constructor(private httpClient: HttpClient) { }

  getCarriers(): Observable<any> {
    return this.httpClient.get<Array<Response>>(`${Config.app.apiUrl}/carrier`);
  }

  resetTestPolicies(carrierId: string): Observable<boolean> {
    return this.httpClient.get<any>(`${Config.app.apiUrl}/carrier/resetpolicy?policy=test&carrier=${carrierId}`);
  }
}



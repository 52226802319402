import { Component, OnInit } from '@angular/core';
import { StorageKey, StorageService } from '@shared/services/storage.service';


@Component({
  selector: 'app-additionalhelp',
  templateUrl: './additionalhelp.component.html',
  styleUrls: ['./additionalhelp.component.scss']
})
export class AdditionalHelpComponent implements OnInit {

  additionalhelp: any;
  carrierId : number;

  constructor(private storage: StorageService) { }

  ngOnInit(): void {
    this.carrierId = Number( this.storage.getItem(StorageKey.carrierId));

  }

  get callCenterRepTitle() { return this.storage.getItem(StorageKey.callCenterRepTitle); }
  get callCenterPhone() { return this.storage.getItem(StorageKey.callCenterPhone); }
  get callCenterHours() { return this.storage.getItem(StorageKey.callCenterHours); }


}

<style>
    @media screen and (max-height: 575px) {

        #rc-imageselect,
        .g-recaptcha {
            transform: scale(0.77);
            -webkit-transform: scale(0.77);
            transform-origin: 0 0;
            -webkit-transform-origin: 0 0;
        }
    }
</style>
<div class="tab-content">
    <div class="tab-pane active card" id="feedbackHome" role="tabpanel">
        <div class="card-body">
            <div class="text-wrap"><small>Thank you for using our website. If you have any thoughts regarding your experience, we encourage that you leave us a message in the form below. </small></div>
            <form focusInvalidInput id="feedback" class="form-signin" (ngSubmit)="onSubmit($event, form)" [formGroup]="form">

                <div class="form-group form-floating mb-3 position-relative">
                    <input type="email" class="form-control" id="email" formControlName="email"
                        required>
                    <label for="email" class="sr-only" ><small>Please enter valid email</small></label>
                    <ng-container *ngIf="email.invalid && (email.dirty || email.touched )" class="alert">
                        <small role="alert" class="error-block text-danger" *ngIf="email.errors?.required"><i
                                class="material-icons me-2">warning</i>Valid email is required
                        </small>
                        <small role="alert" class="error-block text-danger" *ngIf="email.errors?.pattern"><i
                                class="material-icons me-2">warning</i>Enter valid email
                        </small>
                    </ng-container>
                </div>
                <div class="form-group form-floating mb-3 position-relative">
                    <!-- <input type="textarea" class="form-control" id="message"
                        formControlName="message" placeholder="Enter message" required> -->
                    <textarea id="message"  class="form-control form-control-sm"
                         formControlName="message" rows="3" 
                        required maxlength="250"></textarea>
                    <label for="message" class="sr-only" ><small>Please enter feedback up to 250 characters.</small></label>
                    <ng-container *ngIf="message.invalid && (message.dirty || message.touched )" class="alert">
                        <small role="alert" class="error-block text-danger" *ngIf="message.errors.required"><i
                                class="material-icons me-2">warning</i>A response is required
                        </small>
                    </ng-container>
                </div>

                <!-- <div class="form-group">
                    <re-captcha (resolved)="resolved($event)" class="form-control form-control-sm ngrecaptcha"
                        (error)="onError($event)" errorMode="handled" formControlName="recaptchaReactive"
                        #captchaRef="reCaptcha" [siteKey]="googleCaptchaKey" style=" transform:scale(0.8) !important;transform-origin:0 !important;
                            border-color: transparent; margin-left: -5px;" Required>
                    </re-captcha>
                    <ng-container *ngIf="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched )" class="alert">
                        <small class="error-block text-danger" *ngIf="recaptcha.errors.required"><i
                                class="material-icons me-2">warning</i>A response is required
                        </small>
                    </ng-container>
                </div> -->
                <div class="mt-3">
                    <!-- (click)= "captchaRef.reset();"  -->
                    <button  class="btn btn-primary me-2 btn-sm"
                        type="submit">Submit</button>
                </div>
                <!-- <div class="text-success" role="alert">
                    <div id="errorMessage">
                        {{errorMessage}}
                    </div>
                </div> -->
                <!-- Alert Popup-->
                <a style="visibility: hidden;" data-bs-toggle="modal" data-bs-target="#alertdataModal" id="autoTriggerFeedback"  hidden></a>
                <div class="modal fade" id="alertdataModal" data-bs-backdrop="false" data-bs-keyboard="false"
                        tabindex="-1" aria-hidden="true" style="background: #ffffffc7;">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content" style="width:65%;margin:18%;">                                    
                            <div class="modal-header">
                                <h2 class="modal-title">Alert</h2>
                                <button type="button" class="btn-close btn-close-white" #closeId
                                    aria-label="Close"   data-bs-dismiss="modal"></button>
                            </div>
                            <div class="modal-body text-wrap">
                                {{this.errorMessage}}
                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    </div>
</div>
import { FormGroup } from "@angular/forms";
import { CollectorList } from "@shared/models/page-node-model";
import { TimeoutError } from "rxjs";

export class JqueryConversion {
    static initialize() {

    }

    static triggerActionButtonLocal(collector: CollectorList, form: FormGroup, collectors: CollectorList[]): Boolean {
        switch (true) {
            case collector.JQueryCode.includes('.click(function(e) { e.preventDefault(); mobilePhoneSetToPrimary(); });'): //PhoneMobileSetUsePrimary
                let primCollector = collectors.find(c => c.CollectorTypeCd === 'PhonePrimaryNumberHidden');
                let mobileCollector = collectors.find(c => c.CollectorTypeCd === 'PhoneMobileCollector');
                if (primCollector && mobileCollector) {
                    //TODO:database or backend should feed value through DefaultValue property
                    form.get(mobileCollector.HtmlName).setValue(primCollector.Label.replace(/["() -]/g, ""));
                }
                return true;
            // case collector.JQueryCode.includes('.click(function(e) { e.preventDefault(); phoneDialedSet(); });'): //PhonePrimarySetUseCurrent
            //     //for phonescript? 
            //     break;
            default:
                break;
        }
    }

    static addSingleFormControlBehavior() {

    }

    static addInterFormControlBehavior() {

    }

}
import { Component, OnDestroy, OnInit, HostListener, Input, AfterViewInit, AfterContentChecked, AfterViewChecked, OnChanges, SimpleChanges } from '@angular/core';
import { formatDate, Location } from '@angular/common';
import { Observable, forkJoin, combineLatest, of } from 'rxjs';
import { PageNodeService } from '../../shared/page-node.service';
import { Subscription } from 'rxjs';
import { AbstractControl, Form, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { LayoutService } from '../layout/layout.service';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute, Event, Params } from "@angular/router";
import { filter, map, first, mergeMap, take, delay } from 'rxjs/operators';
import { StorageKey, StorageService } from '@shared/services/storage.service';
import { PageNodeComponent } from '@app/question/page-node.component';
import { ToastrService } from 'ngx-toastr';
import { PageNodeModel } from '@shared/models/page-node-model';
import { CommonService } from '@shared/common.service';
import { HttpStateService } from '@shared/services/http-state.service';
import { Title } from "@angular/platform-browser";


@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss']
})

export class PrintComponent implements OnInit, AfterViewChecked, OnChanges {

  @Input() pageNodeType: string;
  carrierlogo: string;
  carrierId: string;
  form: FormGroup;
  page: PageNodeModel;
  page$: Observable<any>;
  confirmationCode: string;
  printerDialog: boolean = false;
  policyNumberLabel: string = "Policy Number:"
  constructor(public activatedRoute: ActivatedRoute,
    public storage: StorageService,
    public pageService: PageNodeService,
    public router: Router,
    public layoutService: LayoutService,
    private commonService: CommonService,
    private httpStateService: HttpStateService,
    private titleService: Title
  ) {
    this.titleService.setTitle("Print Closing Summary");
   }

  ngOnChanges(changes: SimpleChanges): void {

  }

  ngAfterViewChecked(): void {
    if (this.printerDialog) {
      window.print();
      this.printerDialog = false;
    }
  }


  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes.page) {
  //     this.form = this.toFormGroup(this.page.QuestionBlockList);
  //   }
  // }
  ngOnInit(): void {
    this.printerDialog = false;
    this.form = new FormGroup({});
    this.pageNodeType = this.activatedRoute.snapshot.paramMap.get('pageNodeType');
    this.carrierlogo = this.storage.getItem(StorageKey.logoFile);
    this.carrierId = this.storage.getItem(StorageKey.carrierId);
    //make question service call
    this.page$ = combineLatest([
      this.activatedRoute.params,
      this.activatedRoute.queryParams,
    ])
      .pipe(
        mergeMap(val => {
          this.httpStateService.start(this.router.url);
          const page$ = this.pageService.getPrintPage(this.pageNodeType);
          const observables = [page$];
          const joint = forkJoin(observables);
          // joint.subscribe(
          //   s => console.log(s)
          // )
          return joint;
        })
      );

    this.page$.subscribe(
      res => {
        const transform$ = this.commonService.getTransformString('%%WebResponseConfirmationCode%%', null);
        transform$.subscribe(val => {
          this.confirmationCode = val.QuickTransform;
          this.printerDialog = true;
        });

        if(this.router.url.indexOf("myusaa") > 0 || this.carrierId == "104" || this.carrierId == "105" )
        {
          this.policyNumberLabel = "USAA Number";
        }

        this.httpStateService.end(this.router.url);
        this.page = res[0];
        //this.confirmationCode = res[1].QuickTransform;
      },
      error => {
        if (error.status === 401) this.router.navigate(['login']);
        else this.router.navigate(['error']);
      }
    );
  }


  get personSpokenTo() {
    const personSpokenTo = JSON.parse(this.storage.getItem(StorageKey.personSpokenTo));
    return `${personSpokenTo?.firstName} ${personSpokenTo?.lastName}`;
  }

  get policyNumber() {
    const res = JSON.parse(this.storage.getItem(StorageKey.loginResponse));
    return `${res?.Policy?.PolicyNumber}`;
  }

  get datePrint() {
    return formatDate(Date.now(), 'MM/dd/yyyy', 'en');
  }

  sort(array): any {
    return array.sort((a, b) => a.SortOrder - b.SortOrder);
  }

}


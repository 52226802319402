import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FaqService } from '@shared/faq.service';
import { Observable } from 'rxjs/internal/Observable';
import { CommonModule, Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { StorageKey, StorageService } from '@shared/services/storage.service';


@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  faqModel$: Observable<any>;
  @Input() id: number;
  @Input() type1: string;  
  response : any;
  @Output() newItemEvent = new EventEmitter<boolean>();
  constructor(
    private route: ActivatedRoute,
    private faqService: FaqService,
    public storage: StorageService) {
      
    
  }
  ngOnInit(): void {
    if(this.id == null )
    {
       this.id = Number( this.storage.getItem(StorageKey.carrierId));
    }
    if(this.type1 == null)
    {
     this.type1 = this.route.snapshot.paramMap.get('type');
    }
    this.faqModel$ = this.faqService.getFaqs(this.type1,this.id);
    
     this.faqModel$
    .subscribe(
      res => {
        this.response = res;
        this.newItemEvent.emit(this.response.IsFAQAvailable);

      });
  }

}
